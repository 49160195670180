import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { ReactSortable } from 'react-sortablejs'
import { matchPath, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import translate from '../../../helper/translate'
import Checkbox, { StyledBox, StyledCheckboxLabel } from '../../../components/form/Checkbox'
import { updateUserSetting } from '../../../state/actionCreators/app'
import Icon from '../../../components/general/Icon'
import { mobile, VIEWPORT_DESKTOP } from '../../../styles/media'
import ThickHeading from '../../../components/text/ThickHeading'
import Field from '../../../components/form/Field'
import Button from '../../../components/button/Button'
import { hideOverlay } from '../../../helper/router'
import { normalizeColumns } from '../../../helper/collection'
import SkipButton, { StyledSkipButton } from '../../../components/button/SkipButton'
import Modal from '../../../components/router/Modal'

const EditColumns = ({ collections, location, settings, t, updateUserSetting, viewport, visible }) => {
	const match = matchPath(location.pathname, { path: '/collection/:slug/columns', exact: true })
	const collectionSlug = match?.params.slug
	const collectionData = collections ? collections.find(item => item.slug === collectionSlug) : null

	const value = normalizeColumns(settings['collection.columns'], collectionData ? collectionData.type : null)

	const disableLanguage = settings['collection.disableLanguage'] || false
	const disableEdition = settings['collection.disableEdition'] || false
	const disableCondition = settings['collection.disableCondition'] || false
	const disableNotes = settings['collection.disableNotes'] || false
	const disableLocation = settings['collection.disableLocation'] || false
	const disableOrigin = settings['collection.disableOrigin'] || false
	const disableAltered = settings['collection.disableAltered'] || false
	const disableSigned = settings['collection.disableSigned'] || false
	const disableGradedCards = settings['collection.disableGradedCards'] || false

	const options = [
		{
			name: 'haves',
			label: t('general.haves'),
			value: true,
			disabled: true
		},
		{
			name: 'wants',
			label: t('general.wants'),
			value: true,
			disabled: true
		},
		{
			name: 'cardNumber',
			label: t('general.cardNumber'),
			value: true
		},
		{
			name: 'name',
			label: t('general.name'),
			value: true,
			disabled: true
		},
		{
			name: 'price',
			label: t('general.price'),
			value: true
		},
		{
			name: 'rarity',
			label: t('general.rarity'),
			value: true
		},
		{
			name: 'set',
			label: t('general.set'),
			value: false
		},
		{
			name: 'date',
			label: t('general.releaseDate'),
			value: false
		},
		{
			name: 'firstPrint',
			label: t('general.sortByFirstPrint'),
			value: false
		},
		{
			name: 'lastPrint',
			label: t('general.sortByLastPrint'),
			value: false
		},
		{
			name: 'createdAt',
			label: t('general.createdAt'),
			value: false,
			icon: 'clock'
		},
		{
			name: 'updatedAt',
			label: t('general.updatedAt'),
			value: false,
			icon: 'pencil'
		},
		{
			name: 'priceSourceLink',
			label: t('general.priceSourceLink'),
			value: false
		}
	]

	if (!disableLanguage) {
		options.push({
			name: 'language',
			label: t('general.language'),
			value: true
		})
	}

	if (!disableEdition) {
		options.push({
			name: 'edition',
			label: t('general.edition'),
			value: true
		})
	}

	if (!disableCondition) {
		options.push({
			name: 'condition',
			label: t('general.condition'),
			value: true
		})
	}

	if (!disableLocation) {
		options.push({
			name: 'storage',
			label: t('general.storage'),
			value: false
		})
	}

	if (!disableOrigin) {
		options.push({
			name: 'origin',
			label: t('general.origin'),
			value: false
		})
	}

	if (!disableAltered) {
		options.push({
			name: 'altered',
			label: t('general.altered'),
			value: false
		})
	}

	if (!disableSigned) {
		options.push({
			name: 'signed',
			label: t('general.signed'),
			value: false
		})
	}

	if (!disableGradedCards) {
		options.push({
			name: 'grade',
			label: t('general.grade'),
			value: false
		})
	}

	const handleApply = (columns) => {
		updateUserSetting('collection.columns', columns.filter(item => item.value).map(item => item.name), true)
		hideOverlay()
	}

	const reset = () => {
		setColumns(options)
	}

	const getInitialColumns = () => {
		if (value) {
			return options.filter((item) => {
				if (item.value === 'haves' && collectionData && collectionData.type === 'wants') {
					return false
				}

				if (item.value === 'wants' && collectionData && collectionData.type === 'haves') {
					return false
				}

				return true
			}).map((item) => {
				const order = value.indexOf(item.name)

				return {
					...item,
					value: !!value.find(item2 => item2 === item.name),
					order: order === -1 ? 100 : order
				}
			}).sort((a, b) => a.order - b.order)
		}

		return options
	}

	const [columns, setColumns] = useState(getInitialColumns())

	useEffect(() => {
		if (!visible) {
			setColumns(getInitialColumns())
		}
	}, [visible])

	return (
		<Modal path="/collection/:slug/columns" onClickAway={() => handleApply(columns)} onClose={() => handleApply(columns)}>
			{location.pathname === `/collection/${match?.params.slug}/columns` && (
				<Helmet>
					<title>{t('general.editColumns')}</title>
				</Helmet>
			)}

			<ThickHeading>{t('general.editColumns')}</ThickHeading>

			<Field>
				<SortableColumns
					list={columns}
					setList={setColumns}
					handle=".sortable-handle"
				>
					{columns.map(column => (
						<SortableColumn value={column.value} key={column.name}>
							<Checkbox
								small={viewport === VIEWPORT_DESKTOP}
								label={column.label}
								value={column.value}
								onChange={(value) => {
									const newColumns = columns.map(item => item.name === column.name ? {
										...column,
										value
									} : item)
									setColumns(newColumns)
								}}
								disabled={column.disabled}
								disabledBoxOnly
							/>
							<SortableDragHandle className="sortable-handle">
								<Icon name="menu-alt-4" />
							</SortableDragHandle>
						</SortableColumn>
					))}
				</SortableColumns>
			</Field>

			<Buttons>
				<Button onClick={() => handleApply(columns)}>
					{t('general.apply')}
				</Button>

				<SkipButton onClick={reset}>
					{t('general.resetToDefault')}
				</SkipButton>
			</Buttons>
		</Modal>
	)
}

EditColumns.propTypes = {
	collections: PropTypes.array,
	location: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	updateUserSetting: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired
}

EditColumns.defaultProps = {
	collections: null
}

const SortableColumns = styled(ReactSortable)`
	display: flex;
	flex-direction: column;

	.sortable-ghost {
		opacity: 0;
	}

	${StyledCheckboxLabel} {
		margin: 0;
		padding: 0.25rem 0;
		width: 100%;

		align-items: center;
		display: flex;
		flex: 1;

		&:last-child {
			border-bottom: 0;
		}
	}

	${StyledBox} {
		margin-right: 0.5rem;
	}

	${mobile`
		${StyledCheckboxLabel} {
			padding: 0.5rem 0;
		}
	`}
`

const SortableColumn = styled.div`
	align-items: center;
	display: flex;

	overflow: hidden;

	font-size: 1rem;
	font-weight: 500;

	& + & {
		border-top: 1px solid ${props => props.theme.backgroundLight};
	}

	${StyledCheckboxLabel} {
		padding: 0.5rem 0;
	}

	${props => !props.value && `
		color: ${props.theme.textLight};
		font-weight: 400;
	`}

	${mobile`
		width: 100%;
	`}
`

const SortableDragHandle = styled.div`
	color: ${props => props.theme.textLight};
	cursor: move;
	height: 28px;
	width: 36px;

	align-items: center;
	display: flex;
	justify-content: center;

	${mobile`
		height: 36px;
		width: 36px;
	`}
`

const Buttons = styled.div`
	display: flex;
	justify-content: space-between;

	${mobile`
		${StyledSkipButton} {
			font-size: 0.9rem;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		collections: state.persist.collections,
		settings: state.persist.user.settings,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		updateUserSetting
	}, dispatch)),
	translate('general'),
	withRouter
)

export default enhance(EditColumns)
