import { isStandalone } from './src/styles/media'
import { CARD_MARKET, TCG_PLAYER } from './src/data/priceSources'
import { EUR, USD } from './src/data/currencies'

export const siteHasMultipleGames = false
export const siteHasDeckStatus = false

export const siteHasFavorites = false
export const siteHasTargetsDetail = false
export const siteHasFreeTrial = false
export const siteHasYearlyPayments = false

export const siteHasNotifications = false
export const enableServiceWorker = true

export const maintenanceStart = new Date('2024-07-15T06:00:00.000Z')
export const maintenanceEnd = new Date('2024-07-15T08:00:00.000Z')

export const titleSuffix = !isStandalone() ? ' – cardcluster' : ''

export const domains = {
	de: 'cardcluster.de',
	en: 'cardcluster.com',
	fr: 'cardcluster.fr',
	it: 'cardcluster.it',
	es: 'cardcluster.es',
	pt: 'cardcluster.pt'
}

export const htmlHeadMapping = {
	'de-DE': 'de',
	'en-US': 'en',
	'en-GB': 'en',
	'en-FR': 'fr',
	'en-IT': 'it',
	'en-ES': 'es',
	'en-PT': 'pt'
}

export const languageDefaults = {
	de: 'de-DE',
	en: 'en-GB',
	fr: 'en-FR',
	it: 'en-IT',
	es: 'en-ES',
	pt: 'en-PT'
}

export const priceSourceDefaults = {
	de: CARD_MARKET,
	en: TCG_PLAYER,
	fr: CARD_MARKET,
	it: CARD_MARKET,
	es: CARD_MARKET,
	pt: CARD_MARKET
}

export const currencyDefaults = {
	de: EUR,
	en: USD,
	fr: EUR,
	it: EUR,
	es: EUR,
	pt: EUR
}

export const translationsMapping = {
	'de-DE': 'de',
	'en-US': 'en',
	'en-GB': 'en',
	'en-FR': 'en',
	'en-IT': 'en',
	'en-ES': 'en',
	'en-PT': 'en'
}

export const cardNameLanguageMapping = {
	'de-DE': 'de',
	'en-US': 'en',
	'en-GB': 'en',
	'en-FR': 'fr',
	'en-IT': 'it',
	'en-ES': 'es',
	'en-PT': 'pt'
}

export const discordLink = 'https://discord.gg/Ymn6eWMA2S'
