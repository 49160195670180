import {
	CLEAR_ERROR,
	DONE_LOADING, HAVE_RELOADED_CARD_SEARCH_HAVES, HAVE_RELOADED_COLLECTION_CARDS,
	HAVE_RELOADED_MYCOLLECTIONS,
	HAVE_RELOADED_TARGETS,
	HIDE_TOAST, SET_AFFILIATE_RETURN_HASH,
	SET_CACHE,
	SET_CONFIRM,
	SET_ERROR,
	SET_HOVER_CARD,
	SET_LOADING, SET_MODAL_FORM,
	SET_OFFLINE, SET_PRESETS,
	SET_PWA_INSTALL_EVENT, SET_REFERENCE,
	SET_REFRESHING, SET_SEASONS, SET_STORAGES,
	SET_UPDATE_AVAILABLE,
	SET_VIEWPORT, SHOULD_RELOAD_CARD_SEARCH_HAVES, SHOULD_RELOAD_COLLECTION_CARDS,
	SHOULD_RELOAD_MYCOLLECTIONS,
	SHOULD_RELOAD_TARGETS,
	SHOW_TOAST
} from '../actions/app'
import unique from '../../helper/unique'
import history from '../../helper/history'

const initialState = {
	affiliateReturnHash: null,

	// confirm overlay data
	confirm: null,

	// indicates the app is loading
	isLoading: true,

	// time between START_LOADING and TRACK
	loadingStartTime: null,

	// modalForm overlay data
	modalForm: null,

	// offline status
	offline: false,

	// the install event for a browser-triggered install prompt
	pwaInstallEvent: null,

	// what made the user purchase
	reference: null,

	// resolve functions
	refreshing: null,

	// shows an error that interrupts the ui
	siteError: null,

	// short message that appear at the bottom of the screen and disappears without user action required
	toast: null,

	// indicates the user a new service worker is waiting
	updateAvailable: null,

	// possible value: desktop, mobile
	viewport: null,

	// cache the latest model page (could be omitted due to service worker caching)
	cache: {
		archetype: null,
		archetypes: null,
		search: null,
		set: null,

		collectionArchetype: null,
		collectionCard: null,
		collectionCardsOcgOnly: null,
		collectionSet: null
	},

	// loaded data
	presets: null,
	seasons: null,
	storages: null,

	// hoverCard data
	hoverCard: null,
	hoverPosition: null,
	hoverAnimate: false,
	hoverHideImage: false,

	// reload indicators
	reloadCardSearchHaves: false,
	reloadMyCollections: false,
	reloadCollectionCards: false,
	reloadTargets: false,

	// header data
	sidebarRef: null,
	title: null
}

const app = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				loadingStartTime: new Date(),
				isLoading: true,
				title: null
			}
		case DONE_LOADING: {
			const { pathname } = history.location

			const isCardPage = pathname.startsWith('/card/')
			const isArchetypePage = pathname.startsWith('/archetype/') || pathname.startsWith('/series/') || pathname.startsWith('/theme/')
			const clearArchetypeCache = !isCardPage && !isArchetypePage
			const clearArchetypesCache = !isCardPage && !isArchetypePage && !pathname.startsWith('/themes')
			const clearCardCache = !isCardPage && pathname !== '/add'
			const clearOcgOnlyCache = !pathname.startsWith('/cards/ocgonly') && !isCardPage
			const clearSetCache = !isCardPage && !pathname.startsWith('/set/') && pathname !== '/add'
			const clearSearchCache = clearArchetypeCache && clearSetCache && !pathname.startsWith('/tournament/') && !pathname.startsWith('/player/') && !pathname.startsWith('/user/') && !pathname.startsWith('/search/')

			return {
				...state,
				cache: {
					archetype: clearArchetypeCache ? null : state.cache.archetype,
					archetypes: clearArchetypesCache ? null : state.cache.archetypes,
					card: clearCardCache ? null : state.cache.card,
					search: clearSearchCache ? null : state.cache.search,
					set: clearSetCache ? null : state.cache.set,
					collectionArchetype: clearArchetypeCache ? null : state.cache.collectionArchetype,
					collectionCard: clearCardCache ? null : state.cache.collectionCard,
					collectionCardsOcgOnly: clearOcgOnlyCache ? null : state.cache.collectionCardsOcgOnly,
					collectionSet: clearSetCache ? null : state.cache.collectionSet
				},
				loadingStartTime: null,
				isLoading: false,
				refreshing: null,
				title: action.title || state.title
			}
		}

		case SHOULD_RELOAD_CARD_SEARCH_HAVES: {
			return {
				...state,
				reloadCardSearchHaves: true
			}
		}
		case SHOULD_RELOAD_MYCOLLECTIONS: {
			return {
				...state,
				reloadMyCollections: true
			}
		}
		case SHOULD_RELOAD_COLLECTION_CARDS: {
			return {
				...state,
				reloadCollectionCards: true
			}
		}
		case SHOULD_RELOAD_TARGETS: {
			return {
				...state,
				reloadTargets: true
			}
		}

		case HAVE_RELOADED_CARD_SEARCH_HAVES: {
			return {
				...state,
				reloadCardSearchHaves: false
			}
		}
		case HAVE_RELOADED_MYCOLLECTIONS: {
			return {
				...state,
				reloadMyCollections: false
			}
		}
		case HAVE_RELOADED_COLLECTION_CARDS: {
			return {
				...state,
				reloadCollectionCards: false
			}
		}
		case HAVE_RELOADED_TARGETS: {
			return {
				...state,
				reloadTargets: false
			}
		}

		case SET_REFRESHING: {
			const { refreshing } = action

			return {
				...state,
				refreshing
			}
		}
		case SET_ERROR:
			return {
				...state,
				isLoading: false,
				siteError: action.error
			}
		case CLEAR_ERROR:
			return {
				...state,
				siteError: null
			}
		case SHOW_TOAST: {
			return {
				...state,
				toast: {
					id: unique(),
					toastType: action.toastType,
					text: action.text,
					duration: action.duration,
					loading: action.loading
				}
			}
		}
		case HIDE_TOAST: {
			return {
				...state,
				toast: null
			}
		}
		case SET_CACHE:
			return {
				...state,
				cache: {
					...state.cache,
					[action.key]: action.value
				}
			}
		case SET_VIEWPORT:
			return {
				...state,
				viewport: action.viewport
			}
		case SET_HOVER_CARD:
			return {
				...state,
				hoverCard: action.card,
				hoverPosition: action.position,
				hoverAnimate: action.animate,
				hoverHideImage: action.hideImage
			}
		case SET_PWA_INSTALL_EVENT:
			return {
				...state,
				pwaInstallEvent: action.event
			}
		case SET_CONFIRM: {
			const { confirm } = action

			return {
				...state,
				confirm
			}
		}
		case SET_MODAL_FORM: {
			const { modalForm } = action

			return {
				...state,
				modalForm
			}
		}
		case SET_UPDATE_AVAILABLE: {
			const { updateAvailable } = action

			return {
				...state,
				updateAvailable
			}
		}
		case SET_OFFLINE: {
			const { offline } = action

			return {
				...state,
				offline
			}
		}
		case SET_REFERENCE: {
			const { override, reference } = action

			return {
				...state,
				reference: override || !state.reference ? reference : state.reference
			}
		}
		case SET_SEASONS: {
			const { format, seasons } = action

			return {
				...state,
				seasons: {
					...state.seasons,
					[format]: seasons
				}
			}
		}
		case SET_PRESETS: {
			const { presets } = action

			return {
				...state,
				presets,
				presetsLoading: false
			}
		}
		case SET_STORAGES: {
			const { storages } = action

			return {
				...state,
				storages,
				storagesLoading: false
			}
		}
		case SET_AFFILIATE_RETURN_HASH: {
			const { affiliateReturnHash } = action

			return {
				...state,
				affiliateReturnHash
			}
		}
		default:
			return state
	}
}

export default app
