export const backgroundAwareVariables = props => `
	--threshold: 0.5;

	--red: ${props.red};
	--green: ${props.green};
	--blue: ${props.blue};

	--r: calc(var(--red) * 0.2126);
	--g: calc(var(--green) * 0.7152);
	--b: calc(var(--blue) * 0.0722);
	--sum: calc(var(--r) + var(--g) + var(--b));
	--perceived-lightness: calc(var(--sum) / 255);
`

export const backgroundAwareColor = (important = false, alpha = 1) => `
	color: hsla(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%), ${alpha}) ${important ? ' !important' : ''};
`

export const hexToRgb = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16)
	] : null
}

export const rgbToHex = (r, g, b) => '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)

export const formatRgb = (rgbData, opacity = 1) => rgbData ? `rgba(${rgbData[0]}, ${rgbData[1]}, ${rgbData[2]}, ${opacity})` : 'transparent'
