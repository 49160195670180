import {
	doneLoading, setAffiliateReturnHash,
	setHoverCard,
	setLoading,
	setPresets,
	setRefreshing,
	setSeasons,
	setStorages, showToast
} from '../actions/app'
import history from '../../helper/history'
import {
	cardNameLanguageMapping,
	domains,
	priceSourceDefaults,
	titleSuffix,
	translationsMapping
} from '../../../config'
import { events, trackAppType, trackEvent, trackUserType } from '../../helper/track'
import { get, post } from '../../helper/api'
import {
	resetUser,
	setCardNameLanguage,
	setLanguage,
	setUnread,
	setUser,
	setUserSetting,
	setUserSettings
} from '../actions/persist'
import { scrollTop, restoreScrollPosition } from '../../helper/scroll'
import { resetDeckBuilder, transferDeck } from '../actions/deckbuilder'
import { uninstallAllServiceWorkers } from '../../helper/pwa'
import { detectLanguage, getBrowserLanguage } from '../../helper/translate'
import { CARD_MARKET } from '../../data/priceSources'
import formats from '../../data/formats'
import { VIEWPORT_MOBILE } from '../../styles/media'

export const showLoading = () => (dispatch) => {
	if (history.action === 'PUSH' || (history.action === 'POP' && !history.location.state)) {
		scrollTop()
	}

	return dispatch(setLoading())
}

export const track = (title = null, shouldScrollTop = true, omitQueryString = false) => (dispatch, getState) => {
	if (window._paq) {
		let url = window.location.href.toString().split(window.location.host)[1]

		if (omitQueryString) {
			url = url.split('?')[0]
		}

		// prevent double slash in matomo
		if (url === '/') {
			url = ''
		}

		// not available in Matomo 4
		/*if (getState().app.loadingStartTime) {
			window._paq.push(['setGenerationTimeMs', differenceInMilliseconds(new Date(), getState().app.loadingStartTime)])
		} else {
			window._paq.push(['setGenerationTimeMs', 0])
		}*/

		window._paq.push(['setCustomUrl', url])
		window._paq.push(['setDocumentTitle', title ? title.replace(titleSuffix, '') : `${document.title} (generated)`])

		trackAppType(getState().app.viewport)
		trackUserType(getState().persist.authToken, getState().persist.user.subscribed)

		window._paq.push(['trackPageView'])
	}

	// imitate regular page load behavior
	const hash = window.location.hash.substring(1)

	if (history.length !== 1 && !hash) {
		if (history.action === 'PUSH' && shouldScrollTop) {
			scrollTop()
		} else if (history.action === 'POP' && history.location.state) {
			restoreScrollPosition()
		}
	}

	dispatch(resolveRefreshing())

	return dispatch(doneLoading(title ? title.replace(titleSuffix, '') : null))
}

export const resolveRefreshing = () => (dispatch, getState) => {
	if (getState().app.refreshing) {
		getState().app.refreshing()
	}

	return dispatch(setRefreshing(null))
}

export const changeLanguage = language => (dispatch, getState) => {
	dispatch(setLanguage([language, false]))

	if (getState().persist.authToken) {
		dispatch(updateUserSetting('language', language, true))
	}

	trackEvent(events.CHANGE_LANGUAGE, language)
}

export const changeCardNameLanguage = language => (dispatch, getState) => {
	dispatch(setCardNameLanguage(language))

	if (getState().persist.authToken) {
		dispatch(updateUserSetting('app.cardNameLanguage', language, true))
	}
}

export const login = (user, authToken, t) => (dispatch, getState) => {
	trackEvent(events.LOGIN)

	dispatch(resetUser())
	dispatch(transferDeck(user))

	user.settings = user.settings.reduce((accumulator, current) => {
		accumulator[current.name] = current.value

		return accumulator
	}, {})

	dispatch(setUser(authToken, user))

	const browserLanguage = getBrowserLanguage()
	const browserCardNameLanguage = cardNameLanguageMapping[browserLanguage]

	if (window.location.hostname === domains[browserCardNameLanguage]) {
		dispatch(changeLanguage(browserLanguage))
		dispatch(setCardNameLanguage(browserCardNameLanguage))
	} else if (getState().persist.language !== user.language) {
		dispatch(changeLanguage(user.language))
	}

	dispatch(showToast('success', t('general.loginSuccess', { username: user.username })))
}

export const logout = (t = null, reload = false) => (dispatch) => {
	trackEvent(events.LOGOUT)

	post('auth/logout', null, () => {})

	dispatch(resetUser())
	dispatch(resetDeckBuilder())

	const language = detectLanguage(true)

	dispatch(setLanguage(language))
	dispatch(setCardNameLanguage(cardNameLanguageMapping[language[0]]))

	uninstallAllServiceWorkers()

	if (t !== null) {
		dispatch(showToast('success', t('general.logoutSuccess')))
	}

	if (reload) {
		window.setTimeout(() => {
			window.location.reload()
		}, 300)
	} else {
		history.push('/')
	}
}

export const updateUser = () => (dispatch, getState) => {
	if (getState().persist.authToken) {
		get('user/me', ({ data: user }) => {
			user.settings = user.settings.reduce((accumulator, current) => {
				if (current.name === 'app.deckSortAlgorithmMain' || current.name === 'app.deckSortAlgorithmExtra') {
					accumulator[current.name] = current.value.split(',')
				} else {
					accumulator[current.name] = current.value
				}

				return accumulator
			}, {})

			user.settings = getDefaultUserSettings(user.settings, getState)

			dispatch(setUser(getState().persist.authToken, user))
		})

		if (history.location.pathname !== '/') {
			get('user/unread', ({ data }) => {
				dispatch(setUnread(data))
			})
		}
	} else {
		dispatch(setUser(null, {
			...getState().persist.user,
			settings: getDefaultUserSettings(getState().persist.user.settings, getState)
		}))
	}
}

const getDefaultUserSettings = (settings = {}, getState) => {
	if (!settings['app.cardNameLanguage']) {
		settings['app.cardNameLanguage'] = translationsMapping[getState().persist.language]
	}

	if (!settings['app.priceSource']) {
		settings['app.priceSource'] = priceSourceDefaults[translationsMapping[getState().persist.language]] || CARD_MARKET
	}

	return settings
}

export const updateUserSetting = (name, value, persist = false) => (dispatch, getState) => {
	dispatch(setUserSetting(name, value))

	if (persist && getState().persist.authToken) {
		post('user/profile', {
			[name]: value
		}, () => {})
	}
}

export const updateUserSettings = (settings, persist = false) => (dispatch, getState) => {
	const settingsObj = settings.reduce((a, b) => {
		a[b.name] = b.value

		return a
	}, {})

	if (persist && getState().persist.authToken) {
		post('user/profile', settingsObj, () => {})
	}

	return dispatch(setUserSettings(settingsObj))
}

export const updateUsername = username => (dispatch, getState) => {
	const { authToken, user } = getState().persist

	dispatch(setUser(authToken, {
		...user,
		username
	}))
}

export const updateAvatar = avatar => (dispatch, getState) => {
	const { authToken, user } = getState().persist

	dispatch(setUser(authToken, {
		...user,
		avatar,
		avatarInappropriate: false
	}))
}

export const updateTitle = title => (dispatch, getState) => {
	const { authToken, user } = getState().persist

	dispatch(setUser(authToken, {
		...user,
		title
	}))
}

export const updateUserEmailIsVerified = emailIsVerified => (dispatch, getState) => {
	const { authToken, user } = getState().persist

	dispatch(setUser(authToken, {
		...user,
		emailIsVerified
	}))
}

let loadHoverCardReq = null

export const loadHoverCard = (cardId, position, animate, hideImage = false, image = null, deckCardId = null) => (dispatch) => {
	if (loadHoverCardReq) {
		loadHoverCardReq.abort()
	}

	loadHoverCardReq = get(`search/card?cardId=${cardId}`, (card) => {
		dispatch(setHoverCard(card ? {
			...card,
			image,
			deckCardId
		} : { has404: true }, position, animate, hideImage))
	})
}

export const hideHoverCard = () => (dispatch) => {
	if (loadHoverCardReq) {
		loadHoverCardReq.abort()
	}

	dispatch(setHoverCard(null, null, false))
}

export const loadSeasons = format => (dispatch, getState) => {
	if (!format) return

	const { seasons } = getState().app

	if (formats[format].hasSeasons && (!seasons || (seasons && !seasons.hasOwnProperty(format)))) {
		get(`meta/seasons?format=${format}`, (seasons) => {
			dispatch(setSeasons(format, seasons))
		})
	}
}

export const loadPresets = (force = false) => (dispatch, getState) => {
	const { presets } = getState().app

	if (getState().persist.authToken && (!presets || force)) {
		get('presets', ({ data: presets }) => {
			dispatch(setPresets(presets))
		})
	}
}


export const loadStorages = (force = false) => (dispatch, getState) => {
	const { storages } = getState().app

	if (getState().persist.authToken && (!storages || force)) {
		get('collectionStorages', ({ data: storages }) => {
			dispatch(setStorages(storages))
		})
	}
}

export const createAffiliateClick = (priceSource, printId, url) => (dispatch, getState) => {
	const mobile = getState().app.viewport === VIEWPORT_MOBILE
	const guest = !getState().persist.authToken
	const subscribed = !!getState().persist.user.subscribed

	post('out', {
		priceSource,
		printId,
		url,
		mobile,
		guest,
		subscribed
	}, (hash) => {
		dispatch(setAffiliateReturnHash(hash))
	})
}

export const setAffiliateReturned = () => (dispatch, getState) => {
	post('out/return', {
		hash: getState().app.affiliateReturnHash
	}, () => {
		dispatch(setAffiliateReturnHash(null))
	})
}
