import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../general/Icon'
import { backgroundAwareColor, backgroundAwareVariables, hexToRgb } from '../../helper/css'

const CollectionIcon = ({ color, icon, size }) => {
	const rgb = hexToRgb(color)

	return (
		<StyledCollectionIcon
			red={rgb[0]}
			green={rgb[1]}
			blue={rgb[2]}
			size={size}
		>
			<Icon name={icon} />
		</StyledCollectionIcon>
	)
}

CollectionIcon.propTypes = {
	color: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	size: PropTypes.number.isRequired
}

export const StyledCollectionIcon = styled.div`
	background: ${({ red, green, blue }) => `rgb(${red}, ${green}, ${blue})`};
	position: relative;
	text-decoration: none;
	transition: 0.2s;

	${props => props.red !== undefined && props.green !== undefined && props.blue !== undefined ? `
		${backgroundAwareVariables(props)}
		${backgroundAwareColor(true)}
	` : `color: ${props.theme.textLight};`}

	align-items: center;
	display: flex;
	justify-content: center;

	border-radius: ${props => props.size / 10}px;
	height: ${props => props.size}px;
	min-width: ${props => props.size}px;

	svg {
		height: ${props => props.size * 0.5}px;
		width: ${props => props.size * 0.5}px;
		min-height: ${props => props.size * 0.5}px;
		min-width: ${props => props.size * 0.5}px;
		opacity: 0.5;

		${props => props.size === 20 && `
			height: 12px;
			width: 12px;
			min-height: 12px;
			min-width: 12px;
		`}
	}
`

export default CollectionIcon
