import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { relativeTime, relativeTimeUpdateFrequency } from '../../helper/date'
import translate from '../../helper/translate'

const RelativeTime = ({ date, t }) => {
	const [timeStr, setTimeStr] = useState(relativeTime(date, t))
	let timer = null

	const updateTime = () => {
		const frequency = relativeTimeUpdateFrequency(date)

		if (frequency) {
			timer = window.setTimeout(() => {
				setTimeStr(relativeTime(date, t))

				updateTime()
			}, frequency * 1000)
		}
	}

	useEffect(() => {
		if (timer) {
			clearTimeout(timer)
		}

		setTimeStr(relativeTime(date, t))
		updateTime()

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [date])

	return timeStr
}

RelativeTime.propTypes = {
	date: PropTypes.any.isRequired,
	t: PropTypes.func.isRequired
}

const enhance = translate('general')

export default enhance(RelativeTime)
