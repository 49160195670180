import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { capitalize, formatDeckComment, slugifyUsername } from '../../helper/string'
import Avatar from '../../components/user/Avatar'
import ProTag from '../../components/general/ProTag'
import RelativeTime from '../../components/deck/AutoUpdatedTime'
import DropDown from '../../components/button/DropDown'
import Icon from '../../components/general/Icon'
import Link from '../../components/router/Link'
import Tooltip from '../../components/general/Tooltip'
import CommunityButton from '../../components/deck/CommunityButton'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import Tag from '../../components/general/Tag'
import translate from '../../helper/translate'
import DeckCommentForm from './DeckCommentForm'
import { get, post, remove } from '../../helper/api'
import { setConfirm, showToast } from '../../state/actions/app'
import { time } from '../../helper/date'
import OnPageSpinner from '../../components/general/OnPageSpinner'
import { sortComments } from '../../helper/sort'
import Name from '../../components/card/name/Name'
import { StyledCoverImage } from './components/CoverImage'
import Separator from '../../components/button/Separator'
import communityViolations from '../../data/communityViolations'
import Message, { StyledMessage } from '../../components/form/Message'
import { translationsMapping } from '../../../config'
import TranslateWithDeepL from '../../components/translate/TranslateWithDeepL'

class DeckComment extends Component {
	static propTypes = {
		authToken: PropTypes.string,
		autoLoadReplies: PropTypes.bool,
		canEdit: PropTypes.bool.isRequired,
		cardNameLanguage: PropTypes.string,
		comment: PropTypes.object.isRequired,
		comments: PropTypes.array.isRequired,
		commentsOnChange: PropTypes.func.isRequired,
		deck: PropTypes.object.isRequired,
		deckSlug: PropTypes.string.isRequired,
		markAsRead: PropTypes.func.isRequired,
		onAddComment: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		permalink: PropTypes.bool.isRequired,
		permalinkId: PropTypes.number,
		reply: PropTypes.bool,
		repliesCount: PropTypes.number,
		repliesLoaded: PropTypes.bool,
		setConfirm: PropTypes.func.isRequired,
		showToast: PropTypes.func.isRequired,
		sortBy: PropTypes.string.isRequired,
		role: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		threadId: PropTypes.number.isRequired,
		userLanguage: PropTypes.string.isRequired,
		userId: PropTypes.number.isRequired,
		usernames: PropTypes.array.isRequired,
		viewport: PropTypes.string.isRequired,
		votes: PropTypes.number
	}

	static defaultProps = {
		autoLoadReplies: false,
		authToken: null,
		cardNameLanguage: null,
		permalinkId: null,
		repliesCount: 0,
		repliesLoaded: false,
		reply: false,
		votes: 0
	}

	constructor(props) {
		super(props)

		this.state = {
			commentBody: this.props.comment.body,
			translation: null,
			translated: false,
			translationLoading: false,
			language: this.props.comment.language,
			collapsed: this.isLong(),
			edited: this.props.comment.createdAt !== this.props.comment.updatedAt,
			editing: false,
			replying: false,
			showReplies: false,
			repliesLoading: false,
			userVote: this.props.comment.userVote,
			votes: this.props.votes
		}
	}

	componentDidMount() {
		const { autoLoadReplies } = this.props

		if (autoLoadReplies) {
			this.setState({
				showReplies: true
			}, () => this.loadReplies())
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.permalinkId !== this.props.permalinkId) {
			if (this.props.autoLoadReplies) {
				this.setState({
					showReplies: true
				}, () => this.loadReplies())
			} else {
				this.setState({
					showReplies: false
				})
			}
		}
	}

	componentWillUnmount() {
		if (this.req) {
			this.req.abort()
		}
	}

	isLong = () => this.props.comment.body && this.props.comment.body.length > 2000

	translate = () => {
		const { comment, showToast, t, userLanguage } = this.props
		const { translation } = this.state

		if (!translation) {
			this.setState({
				translationLoading: true
			})

			post(`comments/${comment.id}/translate`, null, (translation) => {
				this.setState({
					translation,
					translated: true,
					translationLoading: false
				})
			}, (status, error) => {
				if (error === 'targetEqualsSourceLanguage') {
					showToast('error', t('general.commentTranslationErrorTargetEqualsSourceLanguage', { language: t(`general.language${capitalize(translationsMapping[userLanguage])}`) }))
				} else if (error === 'translationNotAvailable') {
					showToast('error', t('general.commentTranslationNotAvailable'), null)
				}
			})
		} else {
			this.setState(prevState => ({
				...prevState,
				translated: !prevState.translated
			}))
		}
	}

	toggleReplies = () => {
		const { repliesLoaded } = this.props

		this.setState(prevState => ({
			...prevState,
			showReplies: !prevState.showReplies
		}), () => {
			if (this.state.showReplies && !repliesLoaded) {
				this.loadReplies()
			}
		})
	}

	loadReplies = (comments = null) => {
		const { authToken, commentsOnChange, deckSlug, markAsRead, permalinkId, showHidden, sortBy, threadId } = this.props

		if (comments === null) {
			comments = this.props.comments
		}

		this.setState({
			repliesLoading: true
		})

		this.req = get(`decks/${deckSlug}/comments/replies?threadId=${threadId}&showHidden=${showHidden ? 1 : 0}`, ({ data: replies }) => {
			this.setState({
				repliesLoading: false
			}, () => {
				const permalinkData = permalinkId ? replies.find(item => item.id === permalinkId) : null

				commentsOnChange(sortComments([
					...comments.filter(item => item.id !== threadId),
					{
						...comments.find(item => item.id === threadId),
						replies: permalinkId && permalinkData ? [
							{
								...permalinkData,
								permalink: true
							},
							...replies.filter(item => item.id !== permalinkId)
						] : replies
					}
				], sortBy))

				if (authToken && replies.find(item => item.unread)) {
					markAsRead([threadId])
				}
			})
		})
	}

	vote = (upvote) => {
		const { comment, showToast, t } = this.props

		post(`comments/${comment.id}/vote`, {
			upvote
		}, ({ votes, userVote }) => {
			this.setState({
				votes,
				userVote
			})
		}, (status, data) => {
			if (data === 'authorBlockedMe') {
				showToast('error', t('general.voteErrorBlocked'), null)
			}
		})
	}

	delete = () => {
		const { comment, onDelete, setConfirm, t } = this.props

		setConfirm({
			text: t('general.removeCommentConfirm'),
			submitText: t('general.remove'),
			cancelText: t('general.cancel'),
			success: () => {
				this.req = remove(`comments/${comment.id}`, () => {
					onDelete(comment.id)
				})
			}
		})
	}

	render() {
		const { authToken, canEdit, cardNameLanguage, comment, comments, commentsOnChange, deck, deckSlug, onAddComment, permalink, repliesCount, reply, role, sortBy, t, threadId, userLanguage, userId, usernames, viewport } = this.props
		const { collapsed, commentBody, editing, edited, language, replying, showReplies, repliesLoading, translation, translated, translationLoading, votes, userVote } = this.state

		if (comment.deleted) {
			return (
				<Comment className={showReplies ? 'comment-show-replies' : null}>
					<CommentAvatar reply={reply}>
						<a>
							<Avatar avatar="avatar-male" width={reply ? 25 : 40} />
						</a>
					</CommentAvatar>
					<CommentContent reply={reply}>
						<CommentBody>
							<CommentDeleted>{t('general.commentDeleted')}</CommentDeleted>
						</CommentBody>

						{replying && (
							<DeckCommentForm
								autoFocus
								comments={comments}
								commentsOnChange={(comments) => {
									this.setState({
										replying: false,
										showReplies: true
									}, () => {
										if (!showReplies) {
											this.loadReplies(comments)
										} else {
											commentsOnChange(comments)
										}

										onAddComment()
									})
								}}
								deckAuthorUsername={deck.user.username}
								deckSlug={deckSlug}
								mentionUsername={reply ? comment.user.username : null}
								onCancel={() => this.setState({ replying: false })}
								threadId={threadId}
								sortBy={sortBy}
							/>
						)}
						{repliesCount > 0 && (
							<ToggleReplies onClick={this.toggleReplies}>
								<Icon name={showReplies ? 'chevron-up' : 'chevron-down'} />
								{showReplies ? t('general.hideCommentReplies', { count: repliesCount }) : t('general.showCommentReplies', { count: repliesCount })}
							</ToggleReplies>
						)}
						{repliesLoading && (
							<OnPageSpinner delay={false} />
						)}
					</CommentContent>
				</Comment>
			)
		}

		const isLong = this.isLong()
		const authorReplied = !!(repliesCount > 0 && comment.user.username !== deck.user.username && usernames.find(item => item === deck.user.username))
		const canTranslate = true

		return (
			<>
				{permalink && (
					<CommentPermalink reply={reply}>
						{t('general.commentPermalink')}

						<Link to={`/deck/${deckSlug}`}>
							<Icon name="x" />
						</Link>
					</CommentPermalink>
				)}
				<Comment
					className={showReplies ? 'comment-show-replies' : null}
					editing={editing}
					reply={reply}
					permalink={permalink}
				>
					<CommentAvatar reply={reply}>
						{comment.user ? (
							<Link to={`/user/${slugifyUsername(comment.user.username)}`}>
								<Avatar avatar={comment.user.avatar} width={reply ? 25 : 40} />
							</Link>
						) : (
							<a>
								<Avatar avatar="avatar-male" width={reply ? 25 : 40} />
							</a>
						)}
					</CommentAvatar>
					<CommentContent reply={reply}>
						<CommentMeta>
							{comment.user ? (
								<CommentAuthor to={`/user/${slugifyUsername(comment.user.username)}`} deckAuthor={comment.user.username === deck.user.username}>
									{comment.user.username}
									{comment.user.subscribed === 'pro' && <ProTag title={comment.user.title} />}
									<CommentAuthorCount>{comment.user.commentsCount}</CommentAuthorCount>
								</CommentAuthor>
							) : (
								<CommentAuthor deleted as="span">
									{t('general.userDeleted')}
								</CommentAuthor>
							)}

							<CommentTime>
								<Tooltip title={time(comment.createdAt)}>
									<Link to={`/deck/${deckSlug}/comment/${comment.id}`}>
										<RelativeTime date={comment.createdAt} />
									</Link>
								</Tooltip>
								{edited && (
									<>
										&nbsp;({t('general.edited')})
									</>
								)}
							</CommentTime>

							{comment.needsApproval && !comment.approvedAt && (
								<NeedsApproval>{t('general.eventPlayerApprovalPending')}</NeedsApproval>
							)}

							{comment.needsApproval && comment.approvedAt && (
								<NeedsApproval denied>{t('general.commentApprovalDenied')}</NeedsApproval>
							)}

							{comment.unread && (
								<Unread><span />{t('general.new')}</Unread>
							)}

							{authToken && (
								<CommentContextMenu reply={reply}>
									<DropDown
										button={(
											<CommentContextMenuButton>
												<Icon name="dots-horizontal" />
											</CommentContextMenuButton>
										)}
									>
										{onBeforeNavigate => (
											<>
												{comment.own && !comment.needsApproval && (
													<Link onClick={() => this.setState({ editing: true })} onBeforeNavigate={onBeforeNavigate}>
														<Icon name="pencil" />
														{t('general.edit')}
													</Link>
												)}
												{(comment.own || canEdit) && (
													<Link onClick={this.delete} onBeforeNavigate={onBeforeNavigate}>
														<Icon name="trash" />
														{t('general.remove')}
													</Link>
												)}
												{!comment.own && (
													<Link to={`/support?comment=${comment.id}`} onBeforeNavigate={onBeforeNavigate}>
														<Icon name="flag" />
														{t('general.report')}
													</Link>
												)}
												{(role === 'admin' || role === 'mod') && (
													<>
														<Separator />
														<Link to={`/admin/comment/${comment.id}`} onBeforeNavigate={onBeforeNavigate}>
															<Icon name="terminal" />
															{t('general.admin')}
														</Link>
													</>
												)}
											</>
										)}
									</DropDown>
								</CommentContextMenu>
							)}
						</CommentMeta>

						{editing ? (
							<DeckCommentForm
								comment={commentBody}
								commentLanguage={language}
								commentId={comment.id}
								commentOnChange={(commentBody, language) => this.setState({ commentBody, language, editing: false, edited: true })}
								deckAuthorUsername={deck.user.username}
								onCancel={() => this.setState({ editing: false })}
								deckSlug={deckSlug}
								sortBy={sortBy}
								votes={comment.votes}
							/>
						) : (
							<>
								<CommentBody>
									{translated ? (
										<>
											{formatDeckComment(translation, deck, usernames, cardNameLanguage)}
										</>
									) : (
										<>
											{comment.needsApproval && comment.approvedAt && comment.communityViolation && (
												<Message red light>
													{t('general.commentViolatesCommunityGuidelines', { guidelines: <Link to="/docs/community/community-guidelines">{t('general.communityGuidelines')}</Link> })}
													&nbsp;{t(communityViolations.find(item => item.value === comment.communityViolation).label)}
												</Message>
											)}

											{isLong && collapsed ? formatDeckComment(commentBody.substring(0, 2000), deck, usernames, cardNameLanguage) : formatDeckComment(commentBody, deck, usernames, cardNameLanguage)}
											{isLong && collapsed && <CollapseLink onClick={() => this.setState({ collapsed: false })}>{t('general.showMore')}</CollapseLink>}
											{isLong && !collapsed && <CollapseLink onClick={() => this.setState({ collapsed: true })}>{t('general.showLess')}</CollapseLink>}
										</>
									)}

									{canTranslate && (!isLong || !collapsed) && (viewport === VIEWPORT_MOBILE || translated) && (
										<TranslateWithDeepL
											loading={translationLoading}
											onTranslate={this.translate}
											translated={translated}
										/>
									)}
								</CommentBody>
								<CommentFooter>
									<Tooltip title={!comment.user || comment.user.id !== userId ? (userVote === true ? t('general.removeVote') : t('general.voteUp')) : null}>
										<CommunityButton
											green
											light
											active={userVote === true}
											to={!authToken ? '/signup?ref=userFeature' : null}
											disabled={comment.user && comment.user.id === userId}
											onClick={authToken ? () => {
												this.vote(true)
											} : undefined}
										>
											<Icon name="thumbs-up" />
											{votes > 0 && <span>{votes}</span>}
										</CommunityButton>
									</Tooltip>
									<Tooltip title={!comment.user || comment.user.id !== userId ? (userVote === false ? t('general.removeVote') : t('general.voteDown')) : null}>
										<CommunityButton
											red
											light
											active={userVote === false}
											to={!authToken ? '/signup?ref=userFeature' : null}
											disabled={comment.user && comment.user.id === userId}
											onClick={authToken ? () => {
												this.vote(false)
											} : undefined}
										>
											<Icon name="thumbs-down" />
											{votes < 0 && <span>{Math.abs(votes)}</span>}
										</CommunityButton>
									</Tooltip>
									<CommunityButton onClick={() => this.setState({ replying: true })}>
										{t('general.reply')}
									</CommunityButton>
									{viewport === VIEWPORT_DESKTOP && canTranslate && !translated && (
										<CommunityButton right onClick={this.translate}>
											<Icon name="translate" />
											<span>{t('general.translate')}</span>
										</CommunityButton>
									)}
								</CommentFooter>
								{replying && (
									<DeckCommentForm
										autoFocus
										comments={comments}
										commentsOnChange={(comments) => {
											this.setState({
												replying: false,
												showReplies: true
											}, () => {
												if (!showReplies) {
													this.loadReplies(comments)
												} else {
													commentsOnChange(comments)
												}

												onAddComment()
											})
										}}
										deckAuthorUsername={deck.user.username}
										deckSlug={deckSlug}
										mentionUsername={reply && comment.user ? comment.user.username : null}
										onCancel={() => this.setState({ replying: false })}
										threadId={threadId}
										sortBy={sortBy}
									/>
								)}
								{repliesCount > 0 && (
									<ToggleReplies onClick={this.toggleReplies}>
										<Icon name={showReplies ? 'chevron-up' : 'chevron-down'} />
										{authorReplied && <Avatar width={25} avatar={deck.user.avatar} />}
										{showReplies ? t('general.hideCommentReplies', { count: repliesCount }) : t('general.showCommentReplies', { count: repliesCount })}
									</ToggleReplies>
								)}
								{repliesLoading && (
									<OnPageSpinner delay={false} />
								)}
							</>
						)}
					</CommentContent>
				</Comment>
			</>
		)
	}
}

const CommentContextMenu = styled.div`
	position: absolute;
	right: 0;
	top: -6px;
`

const CommentContextMenuButton = styled.button`
	background: transparent;
	border: 0;
	border-radius: 50%;
	color: ${props => props.theme.textVeryLight};
	cursor: pointer;
	transition: 0.1s;

	align-items: center;
	display: flex;
	justify-content: center;

	height: 30px;
	width: 30px;

	${props => props.focused && `
		background: ${props.theme.backgroundVeryLight};
	`}

	${desktop`
		opacity: 0;

		&:hover {
			background: ${props => props.theme.backgroundVeryLight};
		}
	`}

	${mobile`
		&:active {
			background: ${props => props.theme.backgroundVeryLight};
		}
	`}
`

const CommentPermalink = styled.div`
	background: ${props => props.theme.backgroundLight};
	border-radius: 2px;
	color: ${props => props.theme.textLight};
	font-size: 0.9rem;
	font-weight: 600;
	margin-left: -0.5rem;
	margin-bottom: -1rem;
	padding: 0.25rem 0.15rem 0.25rem 0.5rem;
	width: calc(100% + 1rem);

	align-items: center;
	display: flex;
	justify-content: space-between;

	a {
		color: ${props => props.theme.textVeryLight};
		text-decoration: none;
		transition: 0.1s;

		height: 26px;
		width: 26px;

		align-items: center;
		display: flex;
		justify-content: center;

		svg {
			height: 16px;
			width: 16px;
		}

		${desktop`
			&:hover {
				color: ${props => props.theme.textLight};
			}
		`}
	}

	${props => props.reply && `
		margin-bottom: -0.5rem;
		margin-left: calc(1rem + 40px - 0.5rem);
		width: calc(100% - 1rem - 40px - 0.5rem);
	`}

	${mobile`
		${props => props.reply && `
			width: calc(100% - 40px - 0.5rem);
		`}
	`}
`

export const Comment = styled.div`
	display: flex;

	${props => props.permalink && `
		background: ${props.theme.backgroundVeryLight};
		padding: 0.5rem;
		margin-left: -0.5rem;
		width: calc(100% + 1rem);
	`}

	${props => props.reply && `
		margin-left: calc(1rem + 40px - 0.5rem);
		width: calc(100% - 1rem - 40px - 0.5rem);
	`}

	${desktop`
		${props => !props.editing && `
			&:hover {
				${CommentContextMenuButton} {
					opacity: 1;
				}
			}
		`}
	`}

	${mobile`
		${props => props.reply && `
			width: calc(100% - 40px - 0.5rem);
		`}
	`}
`

export const CommentAvatar = styled.div`
	a {
		text-decoration: none;
	}

	${StyledCoverImage}, img, a > svg {
		border-radius: 50%;
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-right: 1rem;

		${props => props.reply && `
			height: 25px;
			width: 25px;
			min-width: 25px;
		`}
	}

	${StyledCoverImage} {
		margin-top: 5px;
	}

	${desktop`
		${StyledCoverImage} > svg {
			border-radius: 0 !important;
			height: 16px !important;
			width: 16px !important;
			min-width: 16px !important;
			margin-right: 0 !important;
		}
	`}

	${mobile`
		${StyledCoverImage} > svg {
			border-radius: 0 !important;
			height: 12px !important;
			width: 12px !important;
			min-width: 12px !important;
			margin-right: 0 !important;
		}
	`}
`

export const CommentContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	max-width: calc(100% - 40px - 1rem);

	${props => props.reply && `
		max-width: calc(100% - 24px - 0.5rem);
	`}
`

export const CommentMeta = styled.div`
	align-items: center;
	display: flex;
	margin-top: -3px;
	margin-left: -3px;
	margin-bottom: calc(0.5rem - 3px);
	max-width: calc(100% - 30px - 0.25rem);
	padding: 3px;
	width: calc(100% + 6px);

	${desktop`
		white-space: nowrap;
	`}

	${mobile`
		display: block;
		line-height: 1.4;

		> * {
			display: inline;
		}
	`}
`

const CommentDeleted = styled.div`
	color: ${props => props.theme.textLight};
	font-style: italic;
`

export const CommentDeckName = styled.span`
	color: ${props => props.theme.text};
	font-weight: 500;
`

export const CommentAuthor = styled(Link)`
	font-size: 0.9rem;
	font-weight: 500;
	text-decoration: none;

	${Tag} {
		font-size: 0.8rem;
		margin-left: 0.25rem;
	}

	${props => props.deckAuthor && `
		background: ${props.theme.backgroundLight};
		box-shadow: 0 0 0 3px ${props.theme.backgroundLight};
		border-radius: 2px;
	`}

	${props => props.deleted && `
		color: ${props.theme.textLight};
		font-style: italic;
	`}
`

export const CommentAuthorCount = styled.span`
	color: ${props => props.theme.primaryGray};
	font-family: ${props => props.theme.fontMonospace};
	font-size: 0.7rem;
	font-weight: 600;
	margin-left: 0.25rem;
`

export const CommentDeck = styled.div`
	color: ${props => props.theme.textLight};
	font-size: 0.9rem;

	${Name} {
		display: inline-flex;
	}

	${CommentAuthor} {
		color: ${props => props.theme.text};
	}
`

export const CommentTime = styled.div`
	margin-left: 0.35rem;

	&, a {
		color: ${props => props.theme.textLight};
		font-size: 0.8rem;
		text-decoration: none;
	}

	${mobile`
		text-overflow: ellipsis;
		overflow: hidden;
	`}
`

export const Unread = styled.div`
	background: ${props => props.theme.primaryVeryLight};
	border-radius: 6px;
	color: ${props => props.theme.primary};
	font-size: 0.8rem;
	font-weight: 600;
	margin-left: 0.5rem;
	padding: 0.15rem 0.35rem;
	text-transform: uppercase;

	align-items: center;
	display: flex;

	span {
		background: ${props => props.theme.primary};
		border-radius: 50%;
		display: inline-block;
		height: 10px;
		width: 10px;
		margin-right: 0.25rem;
	}
`

export const NeedsApproval = styled.div`
	background: ${props => props.theme.orangeLight};
	border-radius: 6px;
	color: rgba(0, 0, 0, 0.4);
	font-size: 0.8rem;
	font-weight: 600;
	margin-left: 0.5rem;
	padding: 0.15rem 0.35rem;
	text-transform: uppercase;

	${props => props.denied && `
		background: ${props.theme.redLight};
	`}

	${mobile`
		font-size: 0.7rem;
	`}
`

export const CommentBody = styled.div`
	font-size: 0.9rem;
	line-height: 1.4;
	white-space: pre-line;

	${StyledMessage} {
		font-size: 0.9rem;
		margin-top: 0 !important;
		margin-bottom: 0.5rem !important;;
		padding: 0.5rem;
	}
`

export const CollapseLink = styled(Link)`
	display: block;
	font-weight: 600;
	margin-top: 0.35rem;
	width: fit-content;
`

export const CommentFooter = styled.div`
	align-items: center;
	display: flex;
	min-height: 28px;
	margin-top: 0.35rem;
	margin-left: -0.5rem;

	${CommunityButton} {
		height: 28px;
		padding: 0.35rem 0.5rem;

		svg {
			height: 16px;
			width: 16px;
		}
	}

	${CommentTime} {
		color: ${props => props.theme.textVeryLight};
		margin-left: 0.35rem;
	}
`

const ToggleReplies = styled(Link)`
	color: ${props => props.theme.primary};
	font-size: 0.8rem;
	font-weight: 600;
	margin-top: 0.25rem;
	margin-left: -2px;
	text-decoration: none;
	user-select: none;
	width: fit-content;

	align-items: center;
	display: flex;

	svg:first-child {
		margin-right: 0.35rem;
	}

	img:not(:first-child), svg:not(:first-child) {
		border-radius: 50%;
		height: 20px;
		width: 20px;
		margin-right: 0.5rem;
	}
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		cardNameLanguage: state.persist.user.settings['app.cardNameLanguage'],
		role: state.persist.user.role,
		userLanguage: state.persist.language,
		userId: state.persist.user.id,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		setConfirm,
		showToast
	}, dispatch)),
	translate('general')
)

export default enhance(DeckComment)
