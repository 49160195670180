import styled from 'styled-components'
import { desktop, mobile } from '../../styles/media'
import { StyledMessage } from '../form/Message'
import ThickHeading from '../text/ThickHeading'
import EndOfListAction from '../list/EndOfListAction'
import { StyledSortAndFilter } from '../list/SortAndFilter'
import InlineField from '../form/InlineField'

export const ListWithSidebar = styled.div`
	display: flex;

	> :first-child {
		flex: 1;
	}

	${mobile`
		flex-direction: column;
	`}

	${StyledMessage} {
		width: 100%;
	}
`

export const Main = styled.div`
	${desktop`
		max-width: ${props => !props.fullWidth ? '800px' : '100%'};
	`}

	${mobile`
		${StyledSortAndFilter} {
			${InlineField} {
				flex: 1;
				min-width: 150px;
			}
		}
	`}
`

export const SidebarContainer = styled.div`
	${desktop`
		height: fit-content;
		margin-left: auto;
		padding-left: 2rem;
		width: 350px;

		${props => props.left && `
			order: -1;
			margin-left: 0;
			padding-left: 0;
			margin-right: auto;
			padding-right: 4rem;
		`}

		${props => props.hasOutline && `
			width: calc(350px + 4rem);
		`}

		${props => props.sticky && `
			position: sticky;
			top: 1rem;
		`}
	`}

	> :first-child > :first-child:not(a) {
		padding-top: 0;
	}

	${mobile`
		${ThickHeading} {
			margin-bottom: 1rem;
		}

		margin-top: 2rem;
	`}

	${props => !props.hoistSidebar && `
		${mobile`
			order: -1;
		`}
	`}
`

export const SidebarSection = styled.div`
	margin-bottom: 2rem;

	${props => props.outline && `
		border: 2px solid ${props.theme.id === 'dark' ? props.theme.backgroundDark : props.theme.backgroundConcrete};
		border-radius: 12px;
		padding: 2rem;
	`}

	> ${ThickHeading} {
		margin-bottom: 1rem;
	}

	${EndOfListAction} {
		margin-top: 1rem;
	}

	${mobile`
		${props => props.outline && `
			padding: 1rem;
		`}
	`}
`

export const CategorySection = styled.div`
	margin-bottom: 1rem;

	strong {
		background: ${props => props.theme.backgroundVeryLight};
		border-radius: 6px;
		color: ${props => props.theme.textLight};
		display: block;
		font-size: 1.1rem;
		font-weight: 500;
		padding: 0.5rem;
		margin-left: -0.5rem;
		margin-bottom: 0.5rem;
		width: calc(100% + 1rem);
	}
`

export const CategoryLabel = styled.div`
	margin-right: auto;

	${props => props.active && `
		color: ${props.theme.textLight};
	`}
`
