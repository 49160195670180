import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { getLazyLoadScrollContainer } from '../../helper/scroll'
import { isPrerender } from '../../styles/media'

const LazyLoadIfNotPrerender = ({ children, height, offset }) => {
	if (isPrerender()) {
		return children
	}

	return (
		<LazyLoad height={height} offset={offset} scrollContainer={getLazyLoadScrollContainer()}>
			{children}
		</LazyLoad>
	)
}

LazyLoadIfNotPrerender.propTypes = {
	children: PropTypes.any.isRequired,
	height: PropTypes.number,
	offset: PropTypes.number
}

LazyLoadIfNotPrerender.defaultProps = {
	height: 30,
	offset: 100
}

export default LazyLoadIfNotPrerender
