import { translationsMapping } from '../../config'
import types from './types'
import cardTypes from './cardTypes'
import monsterAttributes from './monsterAttributes'
import monsterTypes from './monsterTypes'
import banlistStatus from './banlistStatus'
import { sortByName } from '../helper/sort'
import rarities from './rarities'
import { mainMenu } from './mainMenu'
import countries from './countries'
import places from './places'
import effectActions from './effectActions'
import effectPlaces from './effectPlaces'
import cardLanguages from './cardLanguages'
import editions from './editions'
import conditions from './conditions'
import formats, { MASTER_DUEL, OCG, SPEED_DUEL, TCG, TCG_TRADITIONAL } from './formats'
import gradingServices from './gradingServices'
import userTypes from './userTypes'
import { capitalize } from '../helper/string'
import rarityGroups from './rarityGroups'
import communityViolations from './communityViolations'
import contactReasons from './contactReasons'
import currencies from './currencies'
import priceSources from './priceSources'
import reportUserReasons from './reportUserReasons'
import skillCardGroups from './skillCardGroups'

export const cardTypesOptions = (languageCode, type = null) => {
	let entries = Object.entries(cardTypes)

	if (type) {
		entries = entries.filter(([key, value]) => value.type === type)
	}

	return entries.map(([key, value]) => ({
		value: key,
		label: value[`name_${languageCode}`]
	}))
}

export const cardTypesOptionsGrouped = (numbers, languageCode, filter = false) => types.map(type => ({
	label: type[`name_${languageCode}`],
	options: Object.entries(cardTypes)
		.filter(([key, value]) => {
			if (filter) {
				return value.type === type.value && numbers.find(item => item.id === Number(key))
			}

			return value.type === type.value
		})
		.map(([key, value]) => {
			let number = null

			const numberData = numbers && numbers.find(item => item.id === Number(key))

			if (numberData) {
				number = numberData.number
			}

			return {
				value: key,
				label: value[`name_${languageCode}`],
				number
			}
		})
}))

export const cardTypesSkillOptions = languageCode => skillCardGroups.map(skillGroup => ({
	label: skillGroup[`name_${languageCode}`],
	options: sortByName(Object.entries(cardTypes).filter(([key, value]) => skillGroup.members.find(item => item === Number(key))).map(([key, value]) => ({
		value: key,
		label: value[`name_${languageCode}`]
	})), 'label')
}))

export const banlistStatusOptions = language => banlistStatus.map(status => ({
	value: status.value,
	label: status[`name_${translationsMapping[language]}`]
}))

export const monsterAttributeOptions = languageCode => Object.entries(monsterAttributes).map(([key, value]) => ({
	value: key,
	label: value[`name_${languageCode}`],
	order: value.order
})).sort((a, b) => a.order - b.order)

export const monsterTypeOptions = languageCode => sortByName(Object.entries(monsterTypes).map(([key, value]) => ({
	value: key,
	label: value[`name_${languageCode}`]
})), 'label')

export const typesOptions = languageCode => types.map(type => ({
	value: type.value,
	label: type[`name_${languageCode}`]
}))

export const raritiesOptions = (languageCode, format = TCG) => Object.values(rarityGroups)
	.filter(group => group.format === format)
	.map(group => ({
		label: group[`name_${languageCode}`],
		options: group.members.map(id => ({
			value: id,
			label: rarities[id].name
		}))
	}))

export const filteredRaritiesOptions = (data, languageCode, format = TCG) => Object.values(rarityGroups)
	.filter(group => group.format === format)
	.map(group => ({
		label: group[`name_${languageCode}`],
		options: group.members.filter(id => data.find(item => item.id === id)).map(id => ({
			value: id,
			label: rarities[id].name,
			number: data.find(item => item.id === id).number
		}))
	}))

export const mainMenuEntries = t => mainMenu.map(entry => ({
	...entry,
	name: t(entry.name)
}))

export const countryOptions = language => sortByName(Object.entries(countries).map(([key, value]) => ({
	value: key,
	name: value[`name_${translationsMapping[language]}`]
})), 'name')

export const countrySubscriptionOptions = (supportedCountries, language, t) => ['europe', 'northAmerica', 'southAmerica', 'oceania', 'asia'].map(region => ({
	label: t(`general.region_${region}`),
	options: sortByName(Object.entries(countries).filter(([key, value]) => supportedCountries.find(item => item === key) && value.region === region).map(([key, value]) => ({
		value: key,
		name: value[`name_${translationsMapping[language]}`]
	})), 'name')
})).filter(item => item.options.length > 0)

export const stateOptions = country => sortByName(Object.entries(countries[country].states).map(([key, value]) => ({
	value: key,
	name: value.name
})), 'name')

export const formatOptions = () => [
	{
		label: formats[TCG].name,
		value: TCG,
		color: formats[TCG].color
	},
	{
		label: formats[OCG].name,
		value: OCG,
		color: formats[OCG].color
	},
	{
		label: formats[MASTER_DUEL].name,
		value: MASTER_DUEL,
		color: formats[MASTER_DUEL].color
	},
	{
		label: formats[SPEED_DUEL].name,
		value: SPEED_DUEL,
		color: formats[SPEED_DUEL].color
	},
	{
		label: formats[TCG_TRADITIONAL].name,
		value: TCG_TRADITIONAL,
		color: formats[TCG_TRADITIONAL].color
	}
]

export const placeOptions = language => Object.entries(places).map(([key, value]) => ({
	value: key,
	label: value[`nameShort_${translationsMapping[language]}`]
}))

export const effectActionOptions = language => sortByName(Object.entries(effectActions).map(([key, value]) => ({
	value: value.keywords,
	label: value[`name_${translationsMapping[language]}`]
})), 'label', 'name_en')

export const effectPlaceOptions = language => sortByName(Object.entries(effectPlaces).map(([key, value]) => ({
	value: value.keywords,
	label: value[`name_${translationsMapping[language]}`]
})), 'label', 'name_en')

export const effectCardTypeOptions = (language, type = null) => {
	let entries = Object.entries(cardTypes)

	if (type) {
		entries = entries.filter(([key, value]) => value.type === type)
	}

	return entries.map(([key, value]) => ({
		value: value.keywords,
		label: value[`name_${translationsMapping[language]}`]
	}))
}

export const effectMonsterAttributeOptions = language => sortByName(Object.entries(monsterAttributes).map(([key, value]) => ({
	value: value.name_en,
	label: value[`name_${translationsMapping[language]}`]
})), 'label')

export const effectMonsterTypeOptions = language => sortByName(Object.entries(monsterTypes).map(([key, value]) => ({
	value: value.name_en,
	label: value[`name_${translationsMapping[language]}`]
})), 'label')

export const cardLanguageOptionsPlainList = language => sortByName(Object.entries(cardLanguages).filter(([key]) => key !== 'world').map(([key, value]) => ({
	value: key,
	label: value[`name_${translationsMapping[language]}`]
})), 'label')


export const cardLanguageOptions = (language, t) => {
	const defaultLanguages = sortByName(Object.entries(cardLanguages).filter(([key, value]) => key !== 'world' && !value.specificationOf && !value.other).map(([key, value]) => ({
		value: key,
		label: value[`name_${translationsMapping[language]}`]
	})), 'label')

	return [
		...defaultLanguages,
		{
			label: t('general.languageVariants'),
			options: sortByName(Object.entries(cardLanguages).filter(([key, value]) => key !== 'world' && !!value.specificationOf).map(([key, value]) => ({
				value: key,
				label: value[`name_${translationsMapping[language]}`]
			})), 'label')
		},
		{
			label: t('general.other'),
			options: sortByName(Object.entries(cardLanguages).filter(([key, value]) => key !== 'world' && value.other).map(([key, value]) => ({
				value: key,
				label: value[`name_${translationsMapping[language]}`]
			})), 'label')
		}
	]
}

export const editionOptions = language => Object.entries(editions).map(([key, value]) => ({
	value: key,
	label: value[`name_${translationsMapping[language]}`]
}))

export const conditionOptions = (gradingService = null) => {
	let data = conditions

	if (gradingService && gradingServices[gradingService] && gradingServices[gradingService].scale) {
		data = gradingServices[gradingService].scale
	}

	return Object.entries(data).map(([key, value]) => ({
		value: key,
		label: value.name
	}))
}

export const gradingServiceOptions = () => Object.entries(gradingServices).map(([key, value]) => ({
	value: key,
	label: `${key} (${value.name})`
}))

export const userTypeOptions = t => userTypes.map(item => ({
	value: item,
	label: t(`general.userType${capitalize(item)}`)
}))

export const communityViolationOptions = t => communityViolations.map(item => ({
	value: item.value,
	label: t(item.label)
}))

export const reportUserReasonOptions = t => reportUserReasons.map(item => ({
	value: item.value,
	label: t(item.label)
}))

export const contactReasonOptions = t => contactReasons.map(item => ({
	value: item.value,
	label: t(item.label)
}))

export const currencyOptions = language => Object.entries(currencies).map(([key, value]) => ({
	value: key,
	label: value[`name_${translationsMapping[language]}`]
}))

export const priceSourceOptions = priceSourceDefault => Object.entries(priceSources).map(([key, value]) => ({
	value: key,
	label: value.name,
	currency: value.currency,
	...value
})).sort((a, b) => {
	if (Number(a.value) === priceSourceDefault && Number(b.value) !== priceSourceDefault) return -1
	if (Number(a.value) !== priceSourceDefault && Number(b.value) === priceSourceDefault) return 1

	return 0
})
