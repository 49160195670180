import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Message, { StyledMessage } from '../../components/form/Message'
import { desktop, mobile } from '../../styles/media'
import BooleanValue from '../../components/admin/BooleanValue'
import { getAppMenuHeight } from '../../helper/device'

class Toasts extends Component {
	static propTypes = {
		toast: PropTypes.object,
	}

	static defaultProps = {
		toast: null
	}

	showed = null

	hidden = null

	constructor(props) {
		super(props)

		this.state = {
			toast1: null,
			toast2: null
		}

		this.toast1Ref = React.createRef()
		this.toast2Ref = React.createRef()
	}

	componentDidUpdate(prevProps) {
		const { toast } = this.props
		const { toast1 } = this.state

		if (
			(prevProps.toast === null && toast !== null)
			|| (prevProps.toast !== null && toast === null)
			|| (prevProps.toast !== null && toast !== null && prevProps.toast.id !== toast.id)
		) {
			if (toast1 === null) {
				this.setState({
					toast1: toast
				}, () => {
					this.hide(this.toast2Ref, 'toast2')
					this.show(this.toast1Ref, 'toast1')
				})
			} else {
				this.setState({
					toast2: toast
				}, () => {
					this.hide(this.toast1Ref, 'toast1')
					this.show(this.toast2Ref, 'toast2')
				})
			}
		}
	}

	show = (ref, stateProperty) => {
		ref.current.style.opacity = 1
		ref.current.style.transform = 'translate(0, 0)'

		this.showed = new Date().getTime()

		if (this.state[stateProperty] && this.state[stateProperty].duration) {
			window.setTimeout(() => {
				this.hide(ref, stateProperty)
			}, this.state[stateProperty].duration)
		}
	}

	hide = (ref, stateProperty) => {
		// show toast for at least 2 seconds
		const waitMilliseconds = 2000 - (new Date().getTime() - this.showed)

		if (waitMilliseconds > 0) {
			window.setTimeout(() => {
				this.triggerHide(ref, stateProperty)
			}, waitMilliseconds)
		} else {
			this.triggerHide(ref, stateProperty)
		}
	}

	triggerHide = (ref, stateProperty) => {
		ref.current.style.opacity = 0
		ref.current.style.transform = 'translate(0, 100%)'

		window.setTimeout(() => {
			this.setState({
				[stateProperty]: null
			})
		}, 200)
	}

	renderToast = (ref, toast, stateProperty) => (
		<Toast ref={ref} closeable={toast && !toast.duration && !toast.loading}>
			{toast && (
				<Message
					banner
					onClose={!toast.duration ? () => this.hide(ref, stateProperty) : undefined}
					success={toast.toastType === 'success'}
					error={toast.toastType === 'error'}
					warning={toast.toastType === 'warning'}
					info={toast.toastType === 'info'}
				>
					{toast.text}
					<Success className="success"><BooleanValue>{true}</BooleanValue></Success>
				</Message>
			)}
		</Toast>
	)

	render() {
		const { toast1, toast2 } = this.state

		return (
			<>
				{this.renderToast(this.toast1Ref, toast1, 'toast1')}
				{this.renderToast(this.toast2Ref, toast2, 'toast2')}
			</>
		)
	}
}

const Toast = styled.div`
	position: fixed;
	left: 50%;
	bottom: 1rem;
	margin-left: -250px;
	width: 500px;
	z-index: ${props => props.theme.zLayer5};

	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;

	opacity: 0;
	transform: translate(0, 100%);
	transition: all 0.2s ease;

	${mobile`
		left: 0;
		bottom: ${getAppMenuHeight()};
		margin-left: 0;
		width: 100%;
	`}

	${StyledMessage} {
    	border-radius: 4px;
		font-weight: 600;
    	margin: 1rem 0 0;
		padding: 1rem 2rem;
		max-width: 500px;

		button {
			top: 50%;
			right: 0.5rem;
    		margin-top: -20px;
		}

		${desktop`
			box-shadow: 0 0 2px ${props => props.theme.shadowNormal};
			width: fit-content;

			${props => props.closeable && `
				padding: 1rem calc(1rem + 40px) 1rem 2rem;
			`}
		`}

		${mobile`
			border-radius: 0;
			margin: 0;
			padding: 0.5rem 1rem;
			text-align: center;
			width: 100%;

			${props => props.closeable && `
				padding: 0.5rem calc(1rem + 40px) 0.5rem 1rem;
				text-align: left;
			`}
		`}
	}
`

const Success = styled.div`
	background: #d6f5d6;
    border-radius: 4px;
    height: 100%;
    width: 100%;

    align-items: center;
    display: flex;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;

	opacity: 0;
	transition: all 0.2s;

	span {

		transition: all 0.2s;
		transform: scale(0.85);

		${desktop`
			height: 24px;
			width: 24px;

			svg {
				height: 12px;
				width: 12px;
			}
		`}
	}
`

const enhance = connect(state => ({
	toast: state.app.toast
}))

export default enhance(Toasts)
