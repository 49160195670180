import React, { Component } from 'react'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import {
	desktop,
	mobile, VIEWPORT_DESKTOP
} from '../../styles/media'
import Link from '../../components/router/Link'
import Icon from '../../components/general/Icon'
import translate from '../../helper/translate'
import { mainMenuEntries } from '../../data'
import Logo from '../../images/logo/logo.svg'
import FullLogo from '../../images/logo/cardcluster-white.svg'
import { siteHasFavorites, siteHasFreeTrial, siteHasMultipleGames } from '../../../config'
import Tooltip, { StyledTooltip } from '../../components/general/Tooltip'
import DeckLink, { StyledDeckLink } from '../../components/deck/DeckLink'
import CoverImage, { StyledCoverImage } from '../../pages/deck/components/CoverImage'
import { closeDeck } from '../../helper/deck'
import { createDeckSafely, saveDeck } from '../../state/actionCreators/deckbuilder'
import { setConfirm, setReference } from '../../state/actions/app'
import { setDeck, discardChanges, clearDeck } from '../../state/actions/deckbuilder'
import { getPageLocation } from '../../helper/router'
import { toggleMenu } from '../../state/actions/persist'
import history from '../../helper/history'
import { TCG } from '../../data/formats'
import references from '../../data/references'
import { updateUserSetting } from '../../state/actionCreators/app'
import TrialLabel, { StyledTrialLabel } from '../../components/product/TrialLabel'
import CollectionIcon, { StyledCollectionIcon } from '../../components/collection/CollectionIcon'

class MainMenu extends Component {
	static propTypes = {
		authToken: PropTypes.string,
		collections: PropTypes.array,
		clearDeck: PropTypes.func.isRequired,
		createDeckSafely: PropTypes.func.isRequired,
		deck: PropTypes.object,
		discardChanges: PropTypes.func.isRequired,
		hasImagesLicense: PropTypes.bool,
		location: PropTypes.object.isRequired,
		menuCollapsed: PropTypes.bool,
		saveDeck: PropTypes.func.isRequired,
		setConfirm: PropTypes.func.isRequired,
		setDeck: PropTypes.func.isRequired,
		setReference: PropTypes.func.isRequired,
		settings: PropTypes.object.isRequired,
		shelvedDeck: PropTypes.object,
		subscribed: PropTypes.string,
		t: PropTypes.func.isRequired,
		toggleMenu: PropTypes.func.isRequired,
		unreadComments: PropTypes.number,
		updateUserSetting: PropTypes.func.isRequired,
		userId: PropTypes.number.isRequired
	}

	static defaultProps = {
		authToken: null,
		collections: null,
		deck: null,
		hasImagesLicense: false,
		menuCollapsed: false,
		shelvedDeck: null,
		subscribed: null,
		unreadComments: null
	}

	constructor(props) {
		super(props)

		this.state = {
			shouldAnimate: !this.props.location.pathname.startsWith('/deck-builder/') && !this.props.location.pathname.startsWith('/collection/')
		}

		this.dropDownTriggerEl = null
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({
				shouldAnimate: !this.props.location.pathname.startsWith('/deck-builder/') && !prevProps.location.pathname.startsWith('/deck-builder/') && !this.props.location.pathname.startsWith('/collection/') && !prevProps.location.pathname.startsWith('/collection/')
			})
		}
	}

	componentWillUnmount() {
		clearTimeout(this.menuTransitionsTimeout)
	}

	handleToggleMenu = (canExpandMenu) => {
		const { location, toggleMenu, settings, updateUserSetting } = this.props

		const pageLocation = getPageLocation(location)
		const collectionUseFullWidth = (pageLocation.pathname.includes('/collection/') || pageLocation.pathname.startsWith('/add/')) && settings['collection.useFullWidth']

		if (canExpandMenu) {
			if (collectionUseFullWidth) {
				this.setState({
					shouldAnimate: true
				}, () => {
					toggleMenu()
					updateUserSetting('collection.useFullWidth', false, true)
				})
			} else {
				this.setState({
					shouldAnimate: true
				}, () => {
					toggleMenu()
				})
			}
		}
	}

	showDropdown = (event) => {
		this.dropDownTriggerEl = event.currentTarget

		if (this.dropDownTriggerEl) {
			this.dropDownTriggerEl.classList.add('visible')
		}
	}

	hideDropdown = () => {
		if (this.dropDownTriggerEl) {
			this.dropDownTriggerEl.classList.remove('visible')
			this.dropDownTriggerEl = null
		}
	}

	render() {
		const { authToken, clearDeck, createDeckSafely, collections, discardChanges, hasImagesLicense, location, saveDeck, setConfirm, setDeck, settings, setReference, shelvedDeck, subscribed, userId, t } = this.props
		const { shouldAnimate } = this.state

		const deck = this.props.shelvedDeck || this.props.deck

		const defaultHavesCollection = settings['collection.defaultHavesCollection']
		const defaultCollectionData = collections && defaultHavesCollection ? collections.find(item => item.slug === defaultHavesCollection) : null
		const pinDefaultCollection = settings['collection.pinDefaultCollection'] && defaultCollectionData

		const pageLocation = getPageLocation(location)
		const isEditingDeck = pageLocation.pathname.startsWith('/deck-builder/')
		const collectionUseFullWidth = (pageLocation.pathname.includes('/collection/') || pageLocation.pathname.includes('/add/')) && settings['collection.useFullWidth']
		const canExpandMenu = !isEditingDeck

		const menuCollapsed = this.props.menuCollapsed || isEditingDeck || collectionUseFullWidth

		if (location.pathname.startsWith('/login') || location.pathname.startsWith('/signup') || location.pathname.startsWith('/welcome') || (siteHasMultipleGames && location.pathname === '/')) {
			return null
		}

		return ReactDOM.createPortal((
			<>
				<StyledMainMenu menuCollapsed={menuCollapsed} shouldAnimate={shouldAnimate}>
					<NavHeader menuCollapsed={menuCollapsed}>
						<LogoLink to="/">
							<img src={!menuCollapsed ? FullLogo : Logo} alt="" />
							{process.env.NODE_ENV === 'development' && <EnvironmentLocal>local</EnvironmentLocal>}
						</LogoLink>
						{canExpandMenu && <Icon name={menuCollapsed ? 'logout' : 'login'} onClick={() => this.handleToggleMenu(canExpandMenu)} />}
					</NavHeader>

					<SeparatorHeading>{t('general.library')}</SeparatorHeading>

					<RelativeWrapper>
						<Tooltip title={menuCollapsed ? (pinDefaultCollection ? defaultCollectionData.name : t('general.collectionManager')) : null} position="right">
							<MainMenuLink
								to={pinDefaultCollection ? `/collection/${defaultHavesCollection}` : '/mycollections'}
								active={location.pathname === (subscribed ? (pinDefaultCollection ? `/collection/${defaultHavesCollection}` : '/mycollections') : '/product/collection-manager')}
								onBeforeNavigate={() => setReference(references.COLLECTION_TAB)}
								trial={!subscribed}
							>
								{pinDefaultCollection ? (
									<CollectionIcon color={defaultCollectionData.color} icon={defaultCollectionData.icon} size={20} />
								) : (
									<Icon name="archive" />
								)}

								<MenuLinkInner>
									{pinDefaultCollection ? defaultCollectionData.name : t('general.collectionManager')}
									{siteHasFreeTrial && !subscribed && <TrialLabel />}
								</MenuLinkInner>
							</MainMenuLink>
						</Tooltip>
						{pinDefaultCollection ? (
							<MenuLinkSecondary>
								<Tooltip title={t('general.collectionManager')} position="right">
									<MenuLinkSecondaryButton
										onClick={() => {
											setReference(references.COLLECTION_TAB)
											history.push('/mycollections')
										}}
										active={location.pathname === '/mycollections'}
									>
										<Icon name="view-grid" />
									</MenuLinkSecondaryButton>
								</Tooltip>
							</MenuLinkSecondary>
						) : (
							<MenuLinkSecondary>
								<Tooltip title={t('general.newCollection')} position="right">
									<MenuLinkSecondaryButton
										onClick={() => {
											setReference(references.COLLECTION_TAB)
											history.push('/mycollections/collection')
										}}
										active={location.pathname === '/mycollections/collection'}
									>
										<Icon name="add-circle" />
									</MenuLinkSecondaryButton>
								</Tooltip>
							</MenuLinkSecondary>
						)}
					</RelativeWrapper>

					<RelativeWrapper>
						<Tooltip title={menuCollapsed ? t('general.mydecks') : null} position="right">
							<MainMenuLink to="/mydecks" active={location.pathname === '/mydecks'}>
								<Icon name="deck" />
								<span>{t('general.deckBuilder')}</span>
							</MainMenuLink>
						</Tooltip>
						<MenuLinkSecondary>
							<Tooltip title={t('general.newDeck')} position="right">
								<MenuLinkSecondaryButton onClick={() => authToken ? createDeckSafely(TCG, t) : history.push('/signup')}>
									<Icon name="add-circle" />
								</MenuLinkSecondaryButton>
							</Tooltip>
						</MenuLinkSecondary>
					</RelativeWrapper>

					{hasImagesLicense && (
						<MainMenuLink to="/download-card-images" active={location.pathname === '/download-card-images'}>
							<Icon name="photograph" />
							<span>{t('general.scans')}</span>
						</MainMenuLink>
					)}

					{deck && deck.user.id === userId && deck.isEditing && (
						<RelativeWrapper>
							<Tooltip title={menuCollapsed ? deck.name : null} position="right">
								<MainMenuLink
									editingDeck
									to={`/deck-builder${deck.slug ? `/${deck.slug}` : ''}`}
									active={location.pathname === `/deck-builder${deck.slug ? `/${deck.slug}` : ''}`}
								>
									<CoverImage dark deck={deck} backgroundColor="#2D2D2D" />
									<DeckLink deck={deck} editing nowrap />
								</MainMenuLink>
							</Tooltip>
							<MenuLinkSecondary>
								<Tooltip title={t('general.close')} position="right">
									<MenuLinkSecondaryButton onClick={() => closeDeck(deck, shelvedDeck, setConfirm, saveDeck, discardChanges, setDeck, clearDeck, location, VIEWPORT_DESKTOP, t)}>
										<Icon name="x" />
									</MenuLinkSecondaryButton>
								</Tooltip>
							</MenuLinkSecondary>
						</RelativeWrapper>
					)}

					{siteHasFavorites && (
						<RelativeWrapper>
							<Tooltip title={menuCollapsed ? t('general.favorites') : null} position="right">
								<MainMenuLink to="/myfavorites" active={location.pathname === '/myfavorites'}>
									<Icon name="star" />
									<span>{t('general.favorites')}</span>
								</MainMenuLink>
							</Tooltip>
							<MenuLinkSecondary>

							</MenuLinkSecondary>
						</RelativeWrapper>
					)}

					<Separator />

					<SeparatorHeading>{t('general.browse')}</SeparatorHeading>

					<ScrollContainer menuCollapsed={menuCollapsed}>
						<Tooltip title={menuCollapsed ? t('general.topDecks') : null} position="right">
							<MainMenuLink to="/decks" active={location.pathname === '/decks'}>
								<Icon name="fire" />
								<span>{t('general.topDecks')}</span>
							</MainMenuLink>
						</Tooltip>

						<Tooltip title={menuCollapsed ? t('general.cardSearch') : null} position="right">
							<MainMenuLink to="/cards" active={location.pathname.startsWith('/cards') && !location.pathname.startsWith('/cards/spoiler') && !location.pathname.startsWith('/cards/ocgonly')}>
								<Icon name="search" />
								<span>{t('general.cardSearch')}</span>
							</MainMenuLink>
						</Tooltip>
						<Tooltip title={menuCollapsed ? t('general.sets') : null} position="right">
							<MainMenuLink to="/sets" active={location.pathname === '/sets'}>
								<Icon name="folder" />
								<span>{t('general.sets')}</span>
							</MainMenuLink>
						</Tooltip>
						<Tooltip title={menuCollapsed ? t('general.themes') : null} position="right">
							<MainMenuLink to="/themes" active={location.pathname === '/themes'}>
								<Icon name="tag" />
								<span>{t('general.themes')}</span>
							</MainMenuLink>
						</Tooltip>
						<Tooltip title={menuCollapsed ? t('general.events') : null} position="right">
							<MainMenuLink to="/tournaments" active={location.pathname === '/tournaments'}>
								<Icon name="trophy" />
								<span>{t('general.events')}</span>
							</MainMenuLink>
						</Tooltip>
						<Tooltip title={menuCollapsed ? t('general.community') : null} position="right">
							<MainMenuLink to="/community" active={location.pathname === '/community'}>
								<Icon name="chat-alt-2" />
								<span>{t('general.community')}</span>
							</MainMenuLink>
						</Tooltip>

						{!menuCollapsed && (
							<>

								<Separator />

								<SeparatorHeading>{t('general.database')}</SeparatorHeading>

								{mainMenuEntries(t).map(entry => (
									<MainMenuLink to={entry.to} active={location.pathname === entry.to} key={entry.to}>
										{entry.name}
									</MainMenuLink>
								))}
							</>
						)}
					</ScrollContainer>
				</StyledMainMenu>
			</>
		), document.getElementById('root-modal'))
	}
}

const NavHeader = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;

	position: relative;

	svg {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translate(0, -50%);
		opacity: 0;

		color: #AAA;

		&:after {
			position: absolute;
			top: -0.25rem;
			left: -0.25rem;
			z-index: 1;

			border-radius: 50%;
			transition: 0.1s;

			height: calc(100% + 0.5rem);
			width: calc(100% + 0.5rem);

			content: "";
			display: block;
		}

		${props => props.menuCollapsed && `
			right: 0;
		`}

		&:hover {
			color: #FFF;
			cursor: pointer;

			&:after {
				background: rgba(255, 255, 255, 0.1);
			}
		}
	}

	${desktop`
		margin-bottom: 0.5rem;
	`}

	${mobile`
		min-height: 88px;
	`}
`

const StyledMainMenu = styled.nav`
	background: ${props => props.theme.header};
	color: ${props => props.theme.white};

	${props => props.theme.id === 'dark' && `
		box-shadow: ${props.theme.shadowSearch};
	`}

	display: flex;
	flex-direction: column;

	position: fixed;
	top: 0;
	left: 0;

	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 300px;

	transition: 0.2s;
	z-index: ${props => props.theme.zLayer10};

	&:hover {
		${NavHeader} svg {
			opacity: 1 !important;
		}
	}

	${props => props.menuCollapsed && `
		width: 80px;

		a > span, ${StyledDeckLink} {
			opacity: 0;
			transform: translate(-15%);

			position: absolute;
			left: calc(1rem + 20px);
		}

		a > svg, a > ${StyledCollectionIcon} {
			margin-left: 14px;
		}

		${SeparatorHeading}, ${MenuLinkSecondaryButton} {
			display: none;
		}

		${MainMenuLink} {
			padding: 0.9rem 0;
		}
	`}

	${props => !props.shouldAnimate && `
		&, a > span, ${StyledCollectionIcon} {
			transition: none;
		}
	`}

	${StyledTooltip} {
		width: 100%;
	}
`

const ScrollContainer = styled.div`
	height: calc(100vh - 265px);
	padding-bottom: 1rem;
	position: relative;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	${props => !props.menuCollapsed && `
		overflow-y: auto;
	`}
`

const Separator = styled.div`
	height: 23px;
	min-height: 23px;
`

const SeparatorHeading = styled.div`
	color: ${props => props.theme.textVeryLight};
	font-size: 0.9rem;
	font-weight: 600;
	margin: 0 0 0.25rem 30px;
	text-transform: uppercase;
`

const MainMenuDropDown = styled.div`
	position: absolute;
	left: 100%;
	top: 0;
	width: 300px;

	display: none;
	flex-direction: column;

	background: #383838;
	box-shadow: 0 0 6px ${props => props.theme.shadowNormal};

	&:after {
		content: "";
		display: block;

		position: absolute;
		right: 100%;
		top: 50px;
		z-index: 1;

		background: transparent;
		height: 40px;
		width: 25px;
	}
`

const MainMenuLink = styled(Link)`
	border-radius: 12px;
	color: #FFF;
	font-size: 1.1rem;
	min-height: 46px;
	text-decoration: none;

	align-items: center;
	display: flex;

	margin: 0 1rem 1px;
	padding: 0.8rem 1rem;
	position: relative;
	overflow: hidden;
	width: calc(100% - 2rem);
	white-space: nowrap;

	> svg {
		color: rgba(255, 255, 255, 0.6);
		min-width: 20px;
	}

	> span {
		margin-left: 1rem;
		transition: 0.2s;
		white-space: nowrap;

		display: inline-block;
		overflow: hidden;
		max-width: calc(100% - 3rem - 10px);
		text-overflow: ellipsis;

		flex: 1;

		> svg {
			color: rgba(255, 255, 255, 0.3);
			opacity: 0;
			transition: 0.1s;

			position: absolute;
			top: 50%;
			right: 0.5rem;
			transform: translate(0, -50%);
		}
	}

	${StyledCoverImage} {
		position: absolute;
		left: 9px;
	}

	${StyledDeckLink} {
		margin-left: calc(0.3rem + 31px);
		margin-right: 1rem;
	}

	${StyledCollectionIcon} {
		transition: none;
	}

	${props => props.editingDeck && `
		padding: 0 1rem;
	`}

	${props => props.trial && `
		padding: 0.25rem 1rem;
	`}

	&:hover {
		background: rgba(255, 255, 255, 0.1);

		&, & > svg {
			color: #FFF;
		}
	}

	${props => props.active && `
		background: rgba(255, 255, 255, 0.1);

		&, & > svg {
			color: #FFF !important;
		}
	`}
`

const MainMenuDropDownLink = styled(MainMenuLink)`
	border-radius: 0;
	margin: 0;
	padding: 0.9rem 1rem !important;
	width: 100%;
`

const LogoLink = styled(Link)`
	height: 80px;
	padding: 0 0 0 23px;
	position: relative;
	text-decoration: none;
	width: 100%;

	align-items: center;
	display: flex;

	img {
		height: 35px;
		width: auto;
	}
`

const EnvironmentLocal = styled.span`
	background: rgba(0, 0, 0, 0.4);
	border-radius: 2px;
	color: ${props => props.theme.orange};
	font-size: 0.8rem;
	font-weight: 600;
	padding: 0.15rem 0.3rem;
	text-transform: uppercase;

	position: absolute;
	right: 20px;
	bottom: 30px;
`

const RelativeWrapper = styled.div`
	position: relative;

	img {
    	height: 30px;
    	width: 30px;
	}

	> a {
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 2rem;

		> div:first-child {
			position: relative;
			left: -0.3rem;
		}

		span:last-child {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 180px;
		}
	}

	&.visible {
		> a {
			background: rgba(255, 255, 255, 0.1);

			&, & > svg {
				color: #FFF !important;
			}

			> span > svg {
				color: #FFF;
			}
		}

		${MainMenuDropDown} {
			display: flex;
		}
	}
`

const MenuLinkSecondary = styled.div`
	position: absolute;
	right: 1.5rem;
	top: 50%;
	transform: translate(0, -50%);
`

const MenuLinkSecondaryButton = styled.button`
	background: transparent;
	border: 0;
	border-radius: 50%;
	color: #DDD;
	cursor: pointer;
	padding: 0.35rem;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
	}

	&:hover {
		background: #555;
		color: #FFF;
	}

	${props => props.active && `
		background: #555;
		color: #FFF;
	`}

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: ${props => props.theme.zLayer1};

		top: calc(-50% + 2px);
    	right: calc(-50% - 2px + 0.5rem);
		height: 50px;
		width: 40px;
	}
`

export const Editing = styled.div`
	color: ${props => props.theme.textVeryLight};
	font-size: 0.8rem;
	font-weight: 600;
	text-transform: uppercase;
`

const MenuLinkInner = styled.span`
	display: flex;
	flex-direction: column;

	${StyledTrialLabel} {
		margin-top: 0.25rem;
	}
`

const mapStateToProps = state => ({
	authToken: state.persist.authToken,
	collections: state.persist.collections,
	deck: state.deckbuilder.deck,
	hasImagesLicense: state.persist.user.hasImagesLicense,
	language: state.persist.language,
	menuCollapsed: state.persist.menuCollapsed,
	role: state.persist.user.role,
	settings: state.persist.user.settings,
	shelvedDeck: state.deckbuilder.shelvedDeck,
	subscribed: state.persist.user.subscribed,
	unreadComments: state.persist.unreadComments,
	userId: state.persist.user.id
})

const mapDispatchToProps = dispatch => bindActionCreators({
	clearDeck,
	createDeckSafely,
	discardChanges,
	saveDeck,
	setConfirm,
	setDeck,
	setReference,
	toggleMenu,
	updateUserSetting
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	translate('general')
)

export default enhance(MainMenu)
