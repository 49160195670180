const cardTypes = {
	1: {
		name_en: 'Normal',
		name_de: 'Normal',
		name_fr: 'Normal',
		name_it: 'Normale',
		name_es: 'Normal',
		name_pt: 'Normal',
		name_jp: '通常',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(196, 162, 116, 0.3)',
		keywords: 'normal monster',
		multiple: [7, 9, 24]
	},
	2: {
		name_en: 'Effect',
		name_de: 'Effekt',
		name_fr: 'Effet',
		name_it: 'Effetto',
		name_es: 'Efecto',
		name_pt: 'Efeito',
		name_jp: '効果',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(139,98,32, 0.3)',
		keywords: 'effect monster',
		multiple: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
	},
	3: {
		name_en: 'Ritual',
		name_de: 'Ritual',
		name_fr: 'Rituel',
		name_it: 'Rituale',
		name_es: 'Ritual',
		name_pt: 'Ritual',
		name_jp: '儀式',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(73, 106, 185, 0.3)',
		keywords: 'ritual monster',
		multiple: [2, 3, 7, 9, 10, 12, 50]
	},
	4: {
		name_en: 'Fusion',
		name_de: 'Fusion',
		name_fr: 'Fusion',
		name_it: 'Fusione',
		name_es: 'Fusión',
		name_pt: 'Fusão',
		name_jp: '融合',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(127, 92, 176, 0.3)',
		keywords: 'fusion monster',
		multiple: [2, 3, 7, 9, 50]
	},
	5: {
		name_en: 'Synchro',
		name_de: 'Synchro',
		name_fr: 'Synchro',
		name_it: 'Synchro',
		name_es: 'Sincronía',
		name_pt: 'Sincro',
		name_jp: 'シンクロ',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(200, 200, 200, 0.5)',
		keywords: 'synchro monster',
		multiple: [2, 7, 9, 50]
	},
	6: {
		name_en: 'Xyz',
		name_de: 'Xyz',
		name_fr: 'Xyz',
		name_it: 'Xyz',
		name_es: 'Xyz',
		name_pt: 'Xyz',
		name_jp: 'エクシーズ',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(50, 50, 50, 0.5)',
		keywords: 'xyz monster',
		multiple: [2, 7, 50]
	},
	7: {
		name_en: 'Pendulum',
		name_de: 'Pendel',
		name_fr: 'Pendule',
		name_it: 'Pendulum',
		name_es: 'Péndulo',
		name_pt: 'Pêndulo',
		name_jp: 'ペンデュラム',
		type: 'monster',
		primary: false,
		keywords: 'pendulum monster',
		multiple: [1, 2, 3, 4, 5, 6, 9, 10, 12]
	},
	8: {
		name_en: 'Link',
		name_de: 'Link',
		name_fr: 'Lien',
		name_it: 'Link',
		name_es: 'Enlace',
		name_pt: 'Link',
		name_jp: 'リンク',
		type: 'monster',
		primary: true,
		modestColor: 'rgba(45, 83, 161, 0.3)',
		keywords: 'link monster',
		multiple: [2, 50]
	},
	9: {
		name_en: 'Tuner',
		name_de: 'Empfänger',
		name_fr: 'Syntoniseur',
		name_it: 'Tuner',
		name_es: 'Cantante',
		name_pt: 'Regulador',
		name_jp: 'チューナー',
		type: 'monster',
		primary: false,
		keywords: 'tuner',
		multiple: [1, 2, 3, 4, 5, 7, 9, 10, 13, 24, 50]
	},
	10: {
		name_en: 'Flip',
		name_de: 'Flipp',
		name_fr: 'Flip',
		name_it: 'Scoperta',
		name_es: 'Volteo',
		name_pt: 'Virar',
		name_jp: 'リバース',
		type: 'monster',
		primary: false,
		keywords: 'flip monster',
		multiple: [2, 3, 7, 9]
	},
	11: {
		name_en: 'Toon',
		name_de: 'Toon',
		name_fr: 'Toon',
		name_it: 'Toon',
		name_es: 'Toon',
		name_pt: 'Toon',
		name_jp: 'トゥーン',
		type: 'monster',
		primary: false,
		keywords: 'toon monster',
		multiple: [2]
	},
	12: {
		name_en: 'Spirit',
		name_de: 'Spirit',
		name_fr: 'Spirit',
		name_it: 'Spirit',
		name_es: 'Spirit',
		name_pt: 'Espírito',
		name_jp: 'スピリット',
		type: 'monster',
		primary: false,
		keywords: 'spirit monster',
		multiple: [2, 3, 7]
	},
	13: {
		name_en: 'Union',
		name_de: 'Union',
		name_fr: 'Union',
		name_it: 'Unione',
		name_es: 'Unión',
		name_pt: 'Union',
		name_jp: 'ユニオン',
		type: 'monster',
		primary: false,
		keywords: 'union monster',
		multiple: [2, 9]
	},
	14: {
		name_en: 'Gemini',
		name_de: 'Zwilling',
		name_fr: 'Gémeau',
		name_it: 'Gemello',
		name_es: 'Géminis',
		name_pt: 'Gêmeos',
		name_jp: 'デュアル',
		type: 'monster',
		primary: false,
		keywords: 'gemini monster',
		multiple: [2]
	},
	15: {
		name_en: 'Normal',
		name_de: 'Normal',
		name_fr: 'Normale',
		name_it: 'Normale',
		name_es: 'Normales',
		name_pt: 'Normal',
		name_jp: '通常',
		type: 'spell',
		primary: false,
		keywords: 'normal spell'
	},
	16: {
		name_en: 'Continuous',
		name_de: 'Permanent',
		name_fr: 'Continu',
		name_it: 'Continua',
		name_es: 'Continua',
		name_pt: 'Contínua',
		name_jp: '永続',
		type: 'spell',
		primary: false,
		keywords: 'continuous spell'
	},
	17: {
		name_en: 'Field',
		name_de: 'Spielfeld',
		name_fr: 'Terrain',
		name_it: 'Terreno',
		name_es: 'Campo',
		name_pt: 'Campo',
		name_jp: 'フィールド',
		type: 'spell',
		primary: false,
		keywords: 'field spell'
	},
	18: {
		name_de: 'Ausrüstung',
		name_en: 'Equip',
		name_fr: 'Équipement',
		name_it: 'Equipaggiamento',
		name_es: 'Equipo',
		name_pt: 'Equipamento',
		name_jp: '装備',
		type: 'spell',
		primary: false,
		keywords: 'equip spell'
	},
	19: {
		name_en: 'Quick-Play',
		name_de: 'Schnell',
		name_fr: 'Jeu-Rapide',
		name_it: 'Rapida',
		name_es: 'Rápido',
		name_pt: 'Rápida',
		name_jp: '速攻',
		type: 'spell',
		primary: false,
		keywords: 'quick-play spell'
	},
	20: {
		name_en: 'Ritual',
		name_de: 'Ritual',
		name_fr: 'Rituel',
		name_it: 'Rituale',
		name_es: 'Ritual',
		name_pt: 'Ritual',
		name_jp: '儀式',
		type: 'spell',
		primary: false,
		keywords: 'ritual spell'
	},
	21: {
		name_en: 'Normal',
		name_de: 'Normal',
		name_fr: 'Normale',
		name_it: 'Normale',
		name_es: 'Normales',
		name_pt: 'Normal',
		name_jp: '通常',
		type: 'trap',
		primary: false,
		keywords: 'normal trap'
	},
	22: {
		name_en: 'Continuous',
		name_de: 'Permanent',
		name_fr: 'Continu',
		name_it: 'Continua',
		name_es: 'Continua',
		name_pt: 'Contínua',
		name_jp: '永続',
		type: 'trap',
		primary: false,
		keywords: 'continuous trap'
	},
	23: {
		name_en: 'Counter',
		name_de: 'Konter',
		name_fr: 'Contre',
		name_it: 'Contro',
		name_es: 'Contraefecto',
		name_pt: 'Marcador',
		name_jp: 'カウンター',
		type: 'trap',
		primary: false,
		keywords: 'counter trap'
	},
	24: {
		name_en: 'Token',
		name_de: 'Spielmarke',
		name_fr: 'Jeton',
		name_it: 'Segna-Mostro',
		name_es: 'Ficha',
		name_pt: 'Ficha',
		name_jp: 'トークン',
		type: 'monster',
		primary: false,
		keywords: 'token',
		multiple: [1, 9]
	},
	25: {
		name_en: 'Yami Yugi',
		name_de: 'Yami Yugi',
		name_fr: 'Yami Yugi',
		name_it: 'Yami Yugi',
		name_es: 'Yami Yugi',
		name_pt: 'Yami Yugi',
		name_jp: '闇遊戯',
		type: 'skill',
		primary: false
	},
	26: {
		name_en: 'Kaiba',
		name_de: 'Kaiba',
		name_fr: 'Kaiba',
		name_it: 'Kaiba',
		name_es: 'Kaiba',
		name_pt: 'Kaiba',
		name_jp: '海馬瀬人',
		type: 'skill',
		primary: false
	},
	27: {
		name_en: 'Joey',
		name_de: 'Joey',
		name_fr: 'Joey',
		name_it: 'Joey',
		name_es: 'Joey',
		name_pt: 'Joey',
		name_jp: '城之内克也',
		type: 'skill',
		primary: false
	},
	28: {
		name_en: 'Pegasus',
		name_de: 'Pegasus',
		name_fr: 'Pegasus',
		name_it: 'Pegasus',
		name_es: 'Pegasus',
		name_pt: 'Pegasus',
		name_jp: 'ペガサス・Ｊ・クロフォード',
		type: 'skill',
		primary: false
	},
	29: {
		name_en: 'Ishizu',
		name_de: 'Ishizu',
		name_fr: 'Ishizu',
		name_it: 'Ishizu',
		name_es: 'Ishizu',
		name_pt: 'Ishizu',
		name_jp: 'イシズ・イシュタール',
		type: 'skill',
		primary: false
	},
	30: {
		name_en: 'Mai',
		name_de: 'Mai',
		name_fr: 'Mai',
		name_it: 'Mai',
		name_es: 'Mai',
		name_pt: 'Mai',
		name_jp: '孔雀舞',
		type: 'skill',
		primary: false
	},
	31: {
		name_en: 'Bonz',
		name_de: 'Bonz',
		name_fr: 'Bonz',
		name_it: 'Bonz',
		name_es: 'Bonz',
		name_pt: 'Bonz',
		name_jp: 'ゴースト骨塚',
		type: 'skill',
		primary: false
	},
	32: {
		name_en: 'Mako',
		name_de: 'Mako',
		name_fr: 'Mako',
		name_it: 'Mako',
		name_es: 'Mako',
		name_pt: 'Mako',
		name_jp: '梶木漁太',
		type: 'skill',
		primary: false
	},
	33: {
		name_en: 'Keith',
		name_de: 'Keith',
		name_fr: 'Keith',
		name_it: 'Keith',
		name_es: 'Keith',
		name_pt: 'Keith',
		name_jp: 'バンデット・キース',
		type: 'skill',
		primary: false
	},
	34: {
		name_en: 'Rex',
		name_de: 'Rex',
		name_fr: 'Rex',
		name_it: 'Rex',
		name_es: 'Rex',
		name_pt: 'Rex',
		name_jp: 'ダイナソー竜崎',
		type: 'skill',
		primary: false
	},
	35: {
		name_en: 'Weevil',
		name_de: 'Weevil',
		name_fr: 'Weevil',
		name_it: 'Weevil',
		name_es: 'Weevil',
		name_pt: 'Weevil',
		name_jp: 'インセクター羽蛾',
		type: 'skill',
		primary: false
	},
	36: {
		name_en: 'Continuous Spell',
		name_de: 'Permanenter Zauber',
		name_fr: 'Magie Continue',
		name_it: 'Magia Continua',
		name_es: 'Mágica Continua',
		name_pt: null,
		name_jp: null,
		type: 'skill',
		primary: false
	},
	37: {
		name_en: 'Field Spell',
		name_de: 'Spielfeldzauber',
		name_fr: 'Magie de Terrain',
		name_it: 'Magia Terreno',
		name_es: 'Mágica de Campo',
		name_pt: null,
		name_jp: null,
		type: 'skill',
		primary: false
	},
	38: {
		name_en: 'Continuous Trap',
		name_de: 'Permanente Falle',
		name_fr: 'Piège Continu',
		name_it: 'Trappola Continua',
		name_es: 'Trampa Continua',
		name_pt: null,
		name_jp: null,
		type: 'skill',
		primary: false
	},
	39: {
		name_en: 'Christine',
		name_de: 'Christine',
		name_fr: 'Christine',
		name_it: 'Christine',
		name_es: 'Christine',
		name_pt: 'Christine',
		name_jp: '絶好調な学生',
		type: 'skill',
		primary: false
	},
	40: {
		name_en: 'Emma',
		name_de: 'Emma',
		name_fr: 'Emma',
		name_it: 'Emma',
		name_es: 'Emma',
		name_pt: 'Emma',
		name_jp: 'おませな女の子',
		type: 'skill',
		primary: false
	},
	41: {
		name_en: 'Andrew',
		name_de: 'Andrew',
		name_fr: 'Andrew',
		name_it: 'Andrew',
		name_es: 'Andrew',
		name_pt: 'Andrew',
		name_jp: 'クールな学生',
		type: 'skill',
		primary: false
	},
	42: {
		name_en: 'David',
		name_de: 'David',
		name_fr: 'David',
		name_it: 'David',
		name_es: 'David',
		name_pt: 'David',
		name_jp: '礼儀正しいお兄さん',
		type: 'skill',
		primary: false
	},
	43: {
		name_en: 'Yami Marik',
		name_de: 'Yami Marik',
		name_fr: 'Yami Marik',
		name_it: 'Yami Marik',
		name_es: 'Yami Marik',
		name_pt: 'Yami Marik',
		name_jp: '闇マリク',
		type: 'skill',
		primary: false
	},
	44: {
		name_en: 'Yami Bakura',
		name_de: 'Yami Bakura',
		name_fr: 'Yami Bakura',
		name_it: 'Yami Bakura',
		name_es: 'Yami Bakura',
		name_pt: 'Yami Bakura',
		name_jp: '闇獏良(バクラ)',
		type: 'skill',
		primary: false
	},
	45: {
		name_en: 'Espa Roba',
		name_de: 'Espa Roba',
		name_fr: 'Espa Roba',
		name_it: 'Espa Roba',
		name_es: 'Espa Roba',
		name_pt: 'Espa Roba',
		name_jp: 'エスパー絽場',
		type: 'skill',
		primary: false
	},
	46: {
		name_en: 'Odion',
		name_de: 'Odion',
		name_fr: 'Odion',
		name_it: 'Odion',
		name_es: 'Odion',
		name_pt: 'Odion',
		name_jp: 'リシド',
		type: 'skill',
		primary: false
	},
	47: {
		name_en: 'Lumis & Umbra',
		name_de: 'Lumis und Umbra',
		name_fr: 'Lumis & Umbra',
		name_it: 'Lumis & Umbra',
		name_es: 'Lumis & Umbra',
		name_pt: 'Lumis & Umbra',
		name_jp: '「光の仮面」「闇の仮面」',
		type: 'skill',
		primary: false
	},
	48: {
		name_en: 'Arkana',
		name_de: 'Arkana',
		name_fr: 'Arkana',
		name_it: 'Arkana',
		name_es: 'Arkana',
		name_pt: 'Arkana',
		name_jp: 'パンドラ',
		type: 'skill',
		primary: false
	},
	49: {
		name_en: 'Téa',
		name_de: 'Tea',
		name_fr: 'Téa',
		name_it: 'Téa',
		name_es: 'Téa',
		name_pt: 'Téa',
		name_jp: '真崎杏子',
		type: 'skill',
		primary: false
	},
	51: {
		name_en: 'Jaden',
		name_de: 'Jaden',
		name_fr: 'Jaden',
		name_it: 'Jaden',
		name_es: 'Jaden',
		name_pt: 'Jaden',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	52: {
		name_en: 'Aster',
		name_de: 'Aster',
		name_fr: 'Aster',
		name_it: 'Aster',
		name_es: 'Aster',
		name_pt: 'Aster',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	53: {
		name_en: 'Chazz',
		name_de: 'Chazz',
		name_fr: 'Chazz',
		name_it: 'Chazz',
		name_es: 'Chazz',
		name_pt: 'Chazz',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	54: {
		name_en: 'Dr. Crowler',
		name_de: 'Dr. Crowler',
		name_fr: 'Dr. Crowler',
		name_it: 'Dr. Crowler',
		name_es: 'Dr. Crowler',
		name_pt: 'Dr. Crowler',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	55: {
		name_en: 'Alexis',
		name_de: 'Alexis',
		name_fr: 'Alexis',
		name_it: 'Alexis',
		name_es: 'Alexis',
		name_pt: 'Alexis',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	56: {
		name_en: 'Jesse',
		name_de: 'Jesse',
		name_fr: 'Jesse',
		name_it: 'Jesse',
		name_es: 'Jesse',
		name_pt: 'Jesse',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	57: {
		name_en: 'Zane',
		name_de: 'Zane',
		name_fr: 'Zane',
		name_it: 'Zane',
		name_es: 'Zane',
		name_pt: 'Zane',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	58: {
		name_en: 'Axel',
		name_de: 'Axel',
		name_fr: 'Axel',
		name_it: 'Axel',
		name_es: 'Axel',
		name_pt: 'Axel',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	59: {
		name_en: 'Bastion',
		name_de: 'Bastion',
		name_fr: 'Bastion',
		name_it: 'Bastion',
		name_es: 'Bastion',
		name_pt: 'Bastion',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	60: {
		name_en: 'Syrus',
		name_de: 'Syrus',
		name_fr: 'Syrus',
		name_it: 'Syrus',
		name_es: 'Syrus',
		name_pt: 'Syrus',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	61: {
		name_en: 'Hassleberry',
		name_de: 'Hassleberry',
		name_fr: 'Hassleberry',
		name_it: 'Hassleberry',
		name_es: 'Hassleberry',
		name_pt: 'Hassleberry',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	62: {
		name_en: 'Yubel',
		name_de: 'Yubel',
		name_fr: 'Yubel',
		name_it: 'Yubel',
		name_es: 'Yubel',
		name_pt: 'Yubel',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	63: {
		name_en: 'Paradox',
		name_de: 'Paradox',
		name_fr: 'Paradox',
		name_it: 'Paradox',
		name_es: 'Paradox',
		name_pt: 'Paradox',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	64: {
		name_en: 'Chumley',
		name_de: 'Chumley',
		name_fr: 'Chumley',
		name_it: 'Chumley',
		name_es: 'Chumley',
		name_pt: 'Chumley',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	65: {
		name_en: 'Jaden & Syrus',
		name_de: 'Jaden & Syrus',
		name_fr: 'Jaden & Syrus',
		name_it: 'Jaden & Syrus',
		name_es: 'Jaden & Syrus',
		name_pt: 'Jaden & Syrus',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	66: {
		name_en: 'Supreme King',
		name_de: 'Oberster König',
		name_fr: 'Supreme King',
		name_it: 'Supreme King',
		name_es: 'Supreme King',
		name_pt: 'Supreme King',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	67: {
		name_en: 'Nightshroud',
		name_de: 'Nightshroud',
		name_fr: 'Nightshroud',
		name_it: 'Nightshroud',
		name_es: 'Nightshroud',
		name_pt: 'Nightshroud',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	68: {
		name_en: 'Camula',
		name_de: 'Camula',
		name_fr: 'Camula',
		name_it: 'Camula',
		name_es: 'Camula',
		name_pt: 'Camula',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	69: {
		name_en: 'Tania',
		name_de: 'Tania',
		name_fr: 'Tania',
		name_it: 'Tania',
		name_es: 'Tania',
		name_pt: 'Tania',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	70: {
		name_en: 'Titan',
		name_de: 'Titan',
		name_fr: 'Titan',
		name_it: 'Titan',
		name_es: 'Titan',
		name_pt: 'Titan',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	71: {
		name_en: 'Amnael',
		name_de: 'Amnael',
		name_fr: 'Amnael',
		name_it: 'Amnael',
		name_es: 'Amnael',
		name_pt: 'Amnael',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	72: {
		name_en: 'Kagemaru',
		name_de: 'Kagemaru',
		name_fr: 'Kagemaru',
		name_it: 'Kagemaru',
		name_es: 'Kagemaru',
		name_pt: 'Kagemaru',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	73: {
		name_en: 'Adrian',
		name_de: 'Adrian',
		name_fr: 'Adrian',
		name_it: 'Adrian',
		name_es: 'Adrian',
		name_pt: 'Adrian',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	74: {
		name_en: 'Don Zaloog',
		name_de: 'Don Zaloog',
		name_fr: 'Don Zaloog',
		name_it: 'Don Zaloog',
		name_es: 'Don Zaloog',
		name_pt: 'Don Zaloog',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	75: {
		name_en: 'Abidos',
		name_de: 'Abidos',
		name_fr: 'Abidos',
		name_it: 'Abidos',
		name_es: 'Abidos',
		name_pt: 'Abidos',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	76: {
		name_en: 'Viper',
		name_de: 'Viper',
		name_fr: 'Viper',
		name_it: 'Viper',
		name_es: 'Viper',
		name_pt: 'Viper',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	77: {
		name_en: 'Strings',
		name_de: 'Strings',
		name_fr: 'Strings',
		name_it: 'Strings',
		name_es: 'Strings',
		name_pt: 'Strings',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	78: {
		name_en: 'Rare Hunter',
		name_de: 'Raritätenjäger',
		name_fr: 'Rare Hunter',
		name_it: 'Rare Hunter',
		name_es: 'Rare Hunter',
		name_pt: 'Rare Hunter',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	79: {
		name_en: 'Belowski',
		name_de: 'Belowski',
		name_fr: 'Belowski',
		name_it: 'Belowski',
		name_es: 'Belowski',
		name_pt: 'Belowski',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	80: {
		name_en: 'Jim',
		name_de: 'Jim',
		name_fr: 'Jim',
		name_it: 'Jim',
		name_es: 'Jim',
		name_pt: 'Jim',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	81: {
		name_en: 'Sartorius',
		name_de: 'Sartorius',
		name_fr: 'Sartorius',
		name_it: 'Sartorius',
		name_es: 'Sartorius',
		name_pt: 'Sartorius',
		name_jp: '',
		type: 'skill',
		primary: false
	},
	82: {
		name_en: 'Society',
		name_de: 'Society',
		name_fr: 'Society',
		name_it: 'Society',
		name_es: 'Society',
		name_pt: 'Society',
		name_jp: '',
		type: 'skill',
		primary: false
	}
}

export default cardTypes
