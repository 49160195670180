import React, { Component, Fragment } from 'react'
import replace from 'react-string-replace'
import { cardNameLanguageMapping, domains, languageDefaults, translationsMapping } from '../../config'
import store from '../state/store'

/**
 * Use the @translate annotation to import translation strings into a component.
 * This high order component injects the function t as props that you can use to access translations.
 *
 * @param dependencies	string | array
 *
 * @return Wrapped component
 */
const translate = dependencies => ComposedComponent => class extends Component {
	render() {
		const translations = getLanguageData(dependencies, translationsMapping[store.getState().persist.language] || 'en')

		return (
			<ComposedComponent
				{...this.props}
				t={(param, data, plainStringReplace = false) => t(param, data, translations, plainStringReplace)}
				tExplicit={(language, param, data, plainStringReplace = false) => tExplicit(language, param, data, plainStringReplace, dependencies)}
			/>
		)
	}
}

const getLanguageData = (dependencies, language) => {
	const translations = {}

	try {
		if (Array.isArray(dependencies)) {
			for (const item of dependencies) {
				translations[item] = require(`../translations/${item}/${language}.json`)
			}
		} else {
			translations[dependencies] = require(`../translations/${dependencies}/${language}.json`)
		}
	} catch (error) {
		console.error(error)
	}

	return translations
}

const t = (param, data, translations, plainStringReplace) => {
	const arr = param.split('.')
	let output = arr.length === 2 ? translations[arr[0]][arr[1]] : translations[arr[0]]

	// plurals
	if (data && typeof data.count !== 'undefined') {
		const { count } = data

		if (count !== 1) {
			output = arr.length === 2 ? translations[arr[0]][`${arr[1]}Plural`] : translations[`${arr[0]}Plural`]
			data.count = data.count ? data.count.toLocaleString() : 0
		}
	}

	// replacements
	if (data && output) {
		if (plainStringReplace) {
			Object.entries(data).forEach(([key, value]) => {
				output = output.replace(new RegExp(`\{\{${key}\}\}`, 'g'), value)
			})
		} else {
			output = replace(output, /\{\{(.*?)\}\}/g, (match, index) => <Fragment key={index}>{data[match]}</Fragment>)
		}
	}

	return typeof output !== 'undefined' ? output : param
}

export const tExplicit = (language, param, data, plainStringReplace, dependencies) => t(param, data, getLanguageData(dependencies, language), plainStringReplace)

/**
 * Languages are stored as a combination of language code and country code (e.g. en-US).
 * The host language is used whether to suggest the user to use a different domain.
 */
export const detectLanguage = (skipSaved = false) => {
	// use saved language if set
	const stored = store.getState().persist.language

	if (!skipSaved && stored !== null && Object.keys(translationsMapping).find(language => language === stored)) {
		return [stored, false]
	}

	const hostLanguage = getHostLanguage()
	const browserLanguage = getBrowserLanguage()
	const mismatch = hostLanguage !== cardNameLanguageMapping[browserLanguage]

	const translateLanguage = !mismatch ? browserLanguage : languageDefaults[hostLanguage]

	return [translateLanguage, mismatch]
}

const getHostLanguage = () => {
	if (process.env.NODE_ENV === 'development') {
		return 'de'
	}

	const domainMatch = Object.entries(domains).find(([key, value]) => window.location.hostname === value)

	if (domainMatch) {
		return domainMatch[0]
	}

	return 'en'
}

export const getBrowserLanguage = () => {
	for (const language of window.navigator.languages) {
		if (language in translationsMapping) {
			return language
		}

		if (language.includes('de')) {
			return 'de-DE'
		}

		if (language.includes('fr')) {
			return 'en-FR'
		}

		if (language.includes('it')) {
			return 'en-IT'
		}

		if (language.includes('es')) {
			return 'en-ES'
		}

		if (language.includes('pt')) {
			return 'en-PT'
		}
	}

	return 'en-US'
}

export default translate
