import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import Route from '../components/router/Route'
import {
	About,
	Admin,
	AlternateArtworks,
	Archetype,
	Archetypes,
	Article,
	Blog,
	Card,
	Checkout,
	CollectionPage,
	Coupon,
	Community,
	CurrentFormat,
	DecksIndex,
	Docs,
	DowngradeSuccessful,
	EmailNotVerified,
	Event,
	EventPlayerForm,
	Events,
	Format,
	Formats,
	Home,
	Imprint,
	Install,
	Login,
	NotFound,
	OcgOnly,
	PasswordReset,
	PaymentMethodUpdated,
	Player,
	Privacy,
	Register,
	Results,
	ResumeSuccessful,
	Set,
	Sets,
	SetsWithMissingImages,
	Settings,
	SetWithMissingImages,
	SiteSearch,
	Spoiler,
	SwitchSuccessful,
	Terms,
	UpgradeSuccessful,
	User,
	Support,
	VerifyEmail,
	Welcome,
	CountryInvalid,
	Decks,
	MyDecks,
	CollectionTargetForm,
	MyCollections,
	CollectionAddPage,
	CollectionForm,
	Pricing,
	Roadmap,
	DownloadCardImages,
	CollectionHistory,
	MyFavorites,
	CollectionTarget,
	MigratePrices,
	ProductCollectionManager
} from '../pages/imports'
import { siteHasFavorites, siteHasMultipleGames, siteHasTargetsDetail } from '../../config'
import DeckLoader from '../pages/deck/DeckLoader'
import PrivateRoute from '../components/router/PrivateRoute'
import Modal from '../components/router/Modal'
import TabSearch from '../pages/search/CardSearch/TabSearch'
import TabDeck from '../pages/deck/TabDeck'
import { getPageLocation, hideOverlay } from '../helper/router'
import { updateUserSetting } from '../state/actionCreators/app'
import FixedCollapsible from '../components/router/FixedCollapsible'
import EditColumns from '../pages/collection/settings/EditColumns'

const Routes = ({ location, match, game, updateUserSetting }) => {
	// keep current page in background while browsing overlays
	const pageLocation = getPageLocation(location)

	const prefix = siteHasMultipleGames ? match.url : ''

	const setGame = () => {
		const matchGame = match.params.game || 'ygo'

		if (game !== matchGame) {
			updateUserSetting('site.game', matchGame)
		}
	}

	useEffect(() => {
		setGame()
	}, [match.params.game])

	return (
		<>
			{/* Pages */}
			<Switch location={pageLocation}>
				<Route path={`${prefix}/`} exact component={Home} />

				{/* User generated content & user specific pages */}
				<Route path={`${prefix}/:tab(add)/:collectionSlug?/:page?`} component={CollectionAddPage} />
				<Route path={`${prefix}/card/:facet/decks/:page?`} component={Decks} />
				{siteHasTargetsDetail && <Route path={`${prefix}/:tab(collection)/:collectionSlug/targets/:targetSlug/:page?`} component={CollectionTarget} />}
				<Route path={`${prefix}/:tab(collection)/:collectionSlug/:mode(card)/:id`} component={CollectionPage} />
				<Route path={`${prefix}/:tab(collection)/:collectionSlug/:mode(targets|download|upload|imports)/:page?`} component={CollectionPage} />
				<Route path={`${prefix}/:tab(collection)/:collectionSlug/:page?`} component={CollectionPage} />
				<Route path={`${prefix}/community/:tab(following)/:page?`} component={MyDecks} />
				<Route path={`${prefix}/community/(unread|subscribed|rejected)?/:page?`} component={Community} />
				<Route path={`${prefix}/deck/:slug/:navigationTab(prices|test|comment)?/:commentId?`} exact component={DeckLoader} />
				<Route path={`${prefix}/deck-builder/:slug/:modal?`} exact component={DeckLoader} />
				<Route path={`${prefix}/decks/:format(master-duel|ocg|speed-duel|tcg-traditional)?`} exact component={DecksIndex} />
				<Route path={`${prefix}/decks/:format(master-duel|ocg|speed-duel|tcg-traditional)?/:facet/:time?/:page?`} component={Decks} />
				<Route path={`${prefix}/mycollections/selectPriceSource`} component={MigratePrices} />
				<Route path={`${prefix}/mycollections/collection/:slug?`} component={CollectionForm} />
				<Route path={`${prefix}/mycollections`} exact component={MyCollections} />
				{siteHasFavorites && <Route path={`${prefix}/myfavorites/:tab?`} component={MyFavorites} />}
				<Route path={`${prefix}/mydecks/search/:q/:page?`} component={MyDecks} />
				<Route path={`${prefix}/mydecks/:tab?/:page?`} component={MyDecks} />
				<Route path={`${prefix}/user/:slug`} component={User} />
				<Route path={`${prefix}/settings`} component={Settings} />
				<Route path={`${prefix}/submit/:id?`} component={EventPlayerForm} />

				{/* B2B */}
				<PrivateRoute path={`${prefix}/download-card-images`} component={DownloadCardImages} />

				{/* Collection Manager pages */}
				<Route path={`${prefix}/:type(archetype|series|theme)/:slug/:tab(collection|add|sets)?/:collectionSlug?/:page?`} component={Archetype} />
				<Route path={`${prefix}/card/:slug/:tab(collection|add)/:collectionSlug?/:page?`} component={Card} />
				<Route path={`${prefix}/card/:slug/:print?`} exact component={Card} />
				<Route path={`${prefix}/set/:format(master-duel|ocg|speed-duel)/:slug/:tab(collection|add)?/:collectionSlug?`} component={Set} />
				<Route path={`${prefix}/set/:slug/:tab(collection|add)?/:collectionSlug?/:page?`} component={Set} />
				<Route path={`${prefix}/deck/:slug/:navigationTab(prices)/:tab(collection|add)?/:collectionSlug?/:page?`} exact component={DeckLoader} />

				{/* Database */}
				<Route path={`${prefix}/alternateartworks`} component={AlternateArtworks} />
				<Route path={`${prefix}/cards/spoiler/:page?`} component={Spoiler} />
				<Route path={`${prefix}/cards/ocgonly`} component={OcgOnly} />
				<Route path={`${prefix}/format/:type/:date`} component={Format} />
				<Route path={`${prefix}/formats/:type?`} component={Formats} />
				<Route path={`${prefix}/limited-list/:type?`} component={CurrentFormat} />
				<PrivateRoute path={`${prefix}/missing/:language?/:page?`} component={SetsWithMissingImages} />
				<PrivateRoute path={`${prefix}/missinglist/:language/:slug`} component={SetWithMissingImages} />
				<Route path={`${prefix}/player/:slug`} component={Player} />
				<Route path={`${prefix}/search/:q/:category(decks)/:page?`} component={Decks} />
				<Route path={`${prefix}/search/:q?/:category?`} component={SiteSearch} />
				<Route path={`${prefix}/sets/:category?/:category2?/:category3?/:category4?`} component={Sets} />
				<Route path={`${prefix}/themes/:type?`} component={Archetypes} />
				<Route path={`${prefix}/tournament/:eventSlug/submit`} component={EventPlayerForm} />
				<Route path={`${prefix}/tournament/:slug/:page?`} exact component={Event} />
				<Route path={`${prefix}/tournaments/upcoming/:param1?/:param2?/:param3?`} component={Events} />
				<Route path={`${prefix}/tournaments/:param1?/:param2?/:param3?`} component={Results} />

				{/* User auth & sign up */}
				<Route path={`${prefix}/login`} component={Login} />
				<Route path={`${prefix}/passwordreset/:token?`} component={PasswordReset} />
				<Route path={`${prefix}/signup`} component={Register} />
				<PrivateRoute path={`${prefix}/welcome/:step?/:token?`} component={Welcome} />
				<Route path={`${prefix}/verifyemail/:token?`} component={VerifyEmail} />

				{/* Subscription */}
				<Route path={`${prefix}/coupon/:code`} component={Coupon} />
				<Route path={`${prefix}/voucher/:code`} component={Coupon} />
				<PrivateRoute path={`${prefix}/purchase/:price?`} exact component={Checkout} />
				<PrivateRoute path={`${prefix}/purchase/info/emailNotVerified`} component={EmailNotVerified} />
				<PrivateRoute path={`${prefix}/purchase/info/countryInvalid`} component={CountryInvalid} />
				<PrivateRoute path={`${prefix}/purchase/info/upgraded`} component={UpgradeSuccessful} />
				<PrivateRoute path={`${prefix}/purchase/info/switched`} component={SwitchSuccessful} />
				<PrivateRoute path={`${prefix}/purchase/info/downgraded`} component={DowngradeSuccessful} />
				<PrivateRoute path={`${prefix}/purchase/info/paymentMethodUpdated`} component={PaymentMethodUpdated} />
				<PrivateRoute path={`${prefix}/purchase/info/resumed`} component={ResumeSuccessful} />

				{/* Admin */}
				<Route path={`${prefix}/admin`} component={Admin} />

				{/* Miscellaneous */}
				<Route path={`${prefix}/about`} component={About} exact />
				<Route path={`${prefix}/blog`} component={Blog} exact />
				<Route path={`${prefix}/blog/:slug`} component={Article} />
				<Route path={`${prefix}/docs`} component={Docs} />
				<Route path={`${prefix}/install`} component={Install} />
				<Route path={`${prefix}/legal`} component={Imprint} />
				<Route path={`${prefix}/roadmap/idea/:id`} component={Roadmap} />
				<Route path={`${prefix}/roadmap/:status?`} component={Roadmap} />
				<Route path={`${prefix}/privacy`} component={Privacy} />
				<Route path={`${prefix}/pricing/:yearly?`} component={Pricing} />
				<Route path={`${prefix}/product/collection-manager`} component={ProductCollectionManager} />
				<Route path={`${prefix}/support/:presetType?`} component={Support} />
				<Route path={`${prefix}/terms`} component={Terms} />

				<Route path={`${prefix}/cards/:collectionSlug?`} component={null} exact />
				<Route path={`${prefix}/white`} component={null} />

				<Route component={NotFound} />
			</Switch>

			<Modal
				path={`${prefix}/target/:slug?`}
				onClose={hideOverlay}
				onClickAway={hideOverlay}
			>
				<CollectionTargetForm />
			</Modal>

			<EditColumns visible />

			<TabSearch location={pageLocation} />
			<TabDeck location={pageLocation} />

			<FixedCollapsible
				path="/history/:page?"
				component={CollectionHistory}
			/>
		</>
	)
}

Routes.propTypes = {
	location: PropTypes.object.isRequired,
	game: PropTypes.string,
	match: PropTypes.object.isRequired,
	updateUserSetting: PropTypes.func.isRequired
}

Routes.defaultProps = {
	game: null
}

const mapStateToProps = state => ({
	game: state.persist.user.settings['site.game']
})

const mapDispatchToProps = dispatch => bindActionCreators({
	updateUserSetting
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)

export default enhance(Routes)
