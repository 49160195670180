import { isStandalone, VIEWPORT_MOBILE } from '../styles/media'

export const goals = {
	SIGN_UP: 1,
	SUBSCRIBE: 2,
	SUBSCRIPTION_UPGRADE: 4,
	SUBSCRIPTION_DOWNGRADE: 5,
	SUBSCRIPTION_CANCEL: 6,
	SUBSCRIPTION_RESUME: 7,
	SUBSCRIPTION_NOTIFY_ME: 12,
	DELETE_ACCOUNT: 8,
	INSTALL_APP: 9,
	CHROME_PWA_BANNER_ACCEPT: 10,
	CHROME_PWA_BANNER_DISMISS: 11
}

export const events = {
	OPEN_MENU: {
		category: 'App',
		action: 'Menü öffnen'
	},
	CLOSE_MENU: {
		category: 'App',
		action: 'Menü schließen'
	},
	COLLAPSE_SIDEBAR: {
		category: 'App',
		action: 'Seitenleiste zuklappen'
	},
	EXPAND_SIDEBAR: {
		category: 'App',
		action: 'Seitenleiste aufklappen'
	},
	LOGIN: {
		category: 'App',
		action: 'Login'
	},
	LOGIN_FAILED: {
		category: 'App',
		action: 'Anmeldung fehlgeschlagen'
	},
	FORGOT_PASSWORD: {
		category: 'App',
		action: 'Passwort vergessen'
	},
	SIGN_UP_FAILED: {
		category: 'App',
		action: 'Registrierung fehlgeschlagen'
	},
	LOGOUT: {
		category: 'App',
		action: 'Logout'
	},
	UNAUTHORIZED: {
		category: 'App',
		action: 'Sitzung abgelaufen'
	},
	ACCEPT_COOKIES: {
		category: 'App',
		action: 'Cookies akzeptieren'
	},
	GO_TO_SUGGESTED_DOMAIN: {
		category: 'App',
		action: 'Zu vorgeschlagener Domain wechseln'
	},
	STAY_ON_CURRENT_DOMAIN: {
		category: 'App',
		action: 'Auf aktueller Domain bleiben'
	},
	CHANGE_LANGUAGE: {
		category: 'App',
		action: 'Sprache/Region wechseln'
	},
	CLOSE_BANNER: {
		category: 'App',
		action: 'Banner schließen'
	},
	SCROLL_TOP: {
		category: 'App',
		action: 'Nach oben scrollen'
	},
	INSTALL_UPDATE: {
		category: 'App',
		action: 'Update installieren'
	},
	COPY_LINK: {
		category: 'App',
		action: 'Link kopieren'
	},
	COPY_DECKLIST: {
		category: 'App',
		action: 'Deckliste kopieren'
	},
	SHARE: {
		category: 'App',
		action: 'Teilen'
	},
	CHANGE_GAME: {
		category: 'App',
		action: 'Format ändern'
	},
	SUBSCRIPTION_UPDATE_PAYMENT_METHOD: {
		category: 'Subscription',
		action: 'Zahlungsmethode ändern'
	},
	SUBSCRIPTION_FAILED: {
		category: 'Subscription',
		action: 'Formular-Fehler'
	},
	CONTACT: {
		category: 'Support',
		action: 'Nachricht via Kontaktformular'
	},
	JAVASCRIPT_ERROR: {
		category: 'Fehler',
		action: 'JavaScript'
	},
	SERVER_ERROR: {
		category: 'Fehler',
		action: 'Server'
	},
	FORBIDDEN: {
		category: 'Fehler',
		action: 'Zugriff verweigert'
	},
	ZOOM_IN: {
		category: 'Karte',
		action: 'Bild vergrößern'
	},
	ZOOM_OUT: {
		category: 'Karte',
		action: 'Bild verkleinern'
	},
	TRANSLATE: {
		category: 'Karte',
		action: 'Übersetzen'
	},
	EXPAND_SETS: {
		category: 'Karte',
		action: 'Alle Sets anzeigen'
	},
	COLLAPSE_SETS: {
		category: 'Karte',
		action: 'Nur 5 Sets anzeigen'
	},
	FILTER_LANGUAGE: {
		category: 'Karten-Bilder',
		action: 'Sprache filtern'
	},
	FILTER: {
		action: 'Filtern'
	},
	SORT: {
		action: 'Sortieren'
	},
	CHANGE_ARTWORK: {
		action: 'Artwork ändern'
	},
	CHANGE_VIEW: {
		action: 'Ansicht ändern'
	},
	SHOW_ONLY_CHANGES: {
		category: 'Verbotene Karten',
		action: 'Nur Änderungen anzeigen'
	},
	SHOW_FULL_LIST: {
		category: 'Verbotene Karten',
		action: 'Komplette Liste anzeigen'
	},
	COLLAPSE_CARD_SEARCH: {
		category: 'Deck Builder',
		action: 'Kartensuche zuklappen'
	},
	EXPAND_CARD_SEARCH: {
		category: 'Deck Builder',
		action: 'Kartensuche aufklappen'
	},
	CREATE_DECK: {
		category: 'Deck Builder',
		action: 'Neues Deck erstellen'
	},
	FORK_DECK: {
		category: 'Deck Builder',
		action: 'Duplizieren'
	},
	IMPORT_DECK: {
		category: 'Deck Builder',
		action: 'Importieren'
	},
	RENAME_DECK: {
		category: 'Deck Builder',
		action: 'Umbenennen'
	},
	ARCHIVE_DECK: {
		category: 'Deck Builder',
		action: 'Archivieren'
	},
	UNARCHIVE_DECK: {
		category: 'Deck Builder',
		action: 'Aus dem Archiv zurückholen'
	},
	STAR_DECK: {
		category: 'Deck Builder',
		action: 'Zu Favoriten hinzufügen'
	},
	UNSTAR_DECK: {
		category: 'Deck Builder',
		action: 'Aus Favoriten entfernen'
	},
	UPVOTE_DECK: {
		category: 'Deck Builder',
		action: 'Positiv bewerten'
	},
	DOWNVOTE_DECK: {
		category: 'Deck Builder',
		action: 'Negativ bewerten'
	},
	REMOVE_DECK: {
		category: 'Deck Builder',
		action: 'Löschen'
	},
	SAVE_DECK: {
		category: 'Deck Builder',
		action: 'Speichern'
	},
	SHOW_LEGAL_INFO: {
		category: 'Deck Builder',
		action: 'Details zur Spielbarkeit einblenden'
	},
	HIDE_LEGAL_INFO: {
		category: 'Deck Builder',
		action: 'Details zur Spielbarkeit ausblenden'
	},
	SHOW_MISSING_CARDS_INFO: {
		category: 'Deck Builder',
		action: 'Fehlende Karten einblenden'
	},
	HIDE_MISSING_CARDS_INFO: {
		category: 'Deck Builder',
		action: 'Fehlenden Karten ausblenden'
	},
	SHOW_DECK_PRICE_INFO: {
		category: 'Deck Builder',
		action: 'Deck Preise einblenden'
	},
	HIDE_DECK_PRICE_INFO: {
		category: 'Deck Builder',
		action: 'Deck Preise ausblenden'
	},
	SET_MODE: {
		category: 'Deck Builder',
		action: 'Werkzeug wechseln'
	},
	AUTO_SORT: {
		category: 'Deck Builder',
		action: 'Automatisch sortieren'
	},
	SORT_DECK: {
		category: 'Deck Builder',
		action: 'Sortieren'
	},
	DRAW_HAND: {
		category: 'Deck Builder',
		action: 'Starthand ziehen'
	},
	DRAW_CARD: {
		category: 'Deck Builder',
		action: '1 weitere Karte ziehen'
	},
	SHOW_QR_CODE: {
		category: '',
		action: 'QR-Code einblenden'
	},
	SAVE_DECK_ERROR: {
		category: 'Deck Builder',
		action: 'Speichern fehlgeschlagen'
	},
	RESET_CARD_SEARCH: {
		category: 'Kartensuche',
		action: 'Zurücksetzen'
	},
	OPEN_FILTER: {
		category: 'Kartensuche',
		action: 'Filter öffnen'
	},
	APPLY_FILTER: {
		category: 'Kartensuche',
		action: 'Filter anwenden'
	},
	CHANGE_CARD_SEARCH_SORT_BY: {
		category: 'Kartensuche',
		action: 'Sortierung ändern'
	},
	CHANGE_CARD_SEARCH_SORT_ORDER: {
		category: 'Kartensuche',
		action: 'Sortierungsreihenfolge'
	},
	CHANGE_CARD_SEARCH_VIEW: {
		category: 'Kartensuche',
		action: 'Ansicht ändern'
	},
	SELECT_PLAN: {
		category: 'Mitglied werden',
		action: 'Plan auswählen'
	},
	READ_PERK: {
		category: 'Mitglied werden',
		action: 'Beschreibung lesen'
	},
	SUBSCRIBE_CHOOSE_COUNTRY: {
		category: 'Mitglied werden',
		action: 'Land auswählen'
	},
	SUBSCRIBE_SELECT_PAYMENT_METHOD: {
		category: 'Mitglied werden',
		action: 'Zahlungsmethode ändern'
	},
	ENTER_COUPON_SUCCESS: {
		category: 'Mitglied werden',
		action: 'Gültigen Gutschein angewendet'
	},
	ENTER_COUPON_ERROR: {
		category: 'Mitglied werden',
		action: 'Gutschein nicht gefunden'
	},
	ACCEPT_SUBSCRIPTION_TERMS: {
		category: 'Mitglied werden',
		action: 'Zahlungsbedingungen akzeptieren'
	},
	ACCEPT_SEPA_TERMS: {
		category: 'Mitglied werden',
		action: 'Lastschriftbedingungen akzeptieren'
	}
}

export const trackPageView = (url, title) => {
	if (window._paq) {
		window._paq.push(['setCustomUrl', url])
		window._paq.push(['setDocumentTitle', title])
		window._paq.push(['trackPageView'])
	}
}

export const trackGoal = (goal) => {
	if (window._paq) {
		window._paq.push(['trackGoal', goal])
	}
}

export const trackSearch = (q, category, resultsCount, generationTime = 0) => {
	if (window._paq) {
		// not available in Matomo 4
		// window._paq.push(['setGenerationTimeMs', generationTime])
		window._paq.push(['trackSiteSearch', q, category, resultsCount])
	}
}

export const trackEvent = (event, name = undefined, category = undefined) => {
	if (window._paq) {
		window._paq.push(['trackEvent', event.category || category, event.action, name])
	}
}

export const trackDownload = (url) => {
	if (window._paq) {
		window._paq.push(['trackLink', url, 'download'])
	}
}

export const trackAppType = (viewport) => {
	if (window._paq) {
		let appType

		if (viewport === VIEWPORT_MOBILE) {
			if (isStandalone()) {
				appType = 'Mobile PWA'
			} else {
				appType = 'Mobile Browser'
			}
		} else if (isStandalone()) {
			appType = 'Desktop PWA'
		} else {
			appType = 'Desktop Browser'
		}

		window._paq.push(['setCustomDimension', 2, appType])
	}
}

export const trackUserType = (authToken, subscribed) => {
	if (window._paq) {
		let visitorType = 'Gast'

		if (authToken) {
			if (subscribed === 'pro') {
				visitorType = 'Pro'
			} else if (subscribed === 'starter') {
				visitorType = 'Starter'
			} else {
				visitorType = 'Benutzer'
			}
		}

		window._paq.push(['setCustomDimension', 1, visitorType])
	}
}

export const trackHasAdBlocker = (hasAdBlocker, viewport) => {
	if (window._paq) {
		let adblocker = 'Ohne'

		if (hasAdBlocker) {
			if (viewport === VIEWPORT_MOBILE) {
				adblocker = 'Mobile'
			} else {
				adblocker = 'Desktop'
			}
		}

		window._paq.push(['setCustomDimension', 3, adblocker])
	}
}

export const formatFieldErrors = fieldErrors => Object.keys(fieldErrors).reduce((accumulator, currentValue) => `${accumulator}${accumulator ? ', ' : ''}${currentValue}`, '')
