import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { matchPath, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { desktop, mobile } from '../../styles/media'
import unique from '../../helper/unique'
import { getPageLocation } from '../../helper/router'
import { scrollToPosition } from '../../helper/scroll'
import { getAppMenuHeight } from '../../helper/device'

class FixedCollapsible extends Component {
	static propTypes = {
		component: PropTypes.any.isRequired,
		deck: PropTypes.object,
		location: PropTypes.object.isRequired,
		menuCollapsed: PropTypes.bool.isRequired,
		path: PropTypes.string.isRequired,
		userId: PropTypes.number.isRequired
	}

	static defaultProps = {
		deck: null
	}

	constructor(props) {
		super(props)

		this.state = {
			firstLoaded: false
		}

		this.id = unique('fixed-collapsible-')

		this.containerRef = React.createRef()
	}

	componentDidMount() {
		const visible = this.isVisible()
		const { firstLoaded } = this.state

		if (visible) {
			if (!firstLoaded) {
				this.setState({
					firstLoaded: true
				})
			}
		}
	}

	componentDidUpdate(prevProps) {
		const prevVisible = this.isVisible(prevProps.location)
		const visible = this.isVisible()
		const { firstLoaded } = this.state

		if (prevVisible !== visible) {
			if (visible) {
				if (!firstLoaded) {
					this.setState({
						firstLoaded: true
					})
				}

				this.containerRef.current.scrollTo(0, 0)
				this.containerRef.current.style.transform = 'translate(0)'
			} else {
				this.containerRef.current.style.transform = 'translate(100%)'
			}
		}
	}

	getMatch = (location = this.props.location) => matchPath(location.pathname, { path: this.props.path })

	isVisible = (location = this.props.location) => !!this.getMatch(location)

	scrollTop = () => {
		if (this.containerRef.current) {
			scrollToPosition(0, this.containerRef.current)
		}
	}

	render() {
		const { component: Component, deck, location, menuCollapsed, userId } = this.props
		const { firstLoaded } = this.state
		const visible = !!this.getMatch()

		const pageLocation = getPageLocation(location)
		const match = this.getMatch(location)

		return (
			<>
				<StyledFixedCollapsible
					menuCollapsed={menuCollapsed || pageLocation.pathname.startsWith('/collection/') || (pageLocation.pathname.startsWith('/deck-builder/') && deck && deck.user.id === userId)}
					visible={visible}
					ref={this.containerRef}
				>
					{firstLoaded && (
						<Component
							location={location}
							match={match}
							scrollTop={this.scrollTop}
							visible={visible}
						/>
					)}
				</StyledFixedCollapsible>
				<MenuBackdrop visible={visible} />
			</>
		)
	}
}

const StyledFixedCollapsible = styled.div`
	background: ${props => props.theme.background};
	color: ${props => props.theme.text};
	position: fixed;
	top: 0;
	right: 0;

	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	transition: all 0.2s;
	opacity: ${props => props.visible ? 1 : 0};
	transform: translate(${props => props.visible ? '0' : '100%'});
	z-index: ${props => props.theme.zLayer12};

	display: flex;
	flex-direction: column;

	${desktop`
		width: 450px;
	`}

	${mobile`
		height: calc(100vh - 44px - ${getAppMenuHeight()});
		height: calc(var(--vh, 1vh) * 100 - 44px - ${getAppMenuHeight()});
		opacity: 1;
		top: 44px;

		transition: none;
		width: 100%;
	`}
`

const MenuBackdrop = styled.div`
	background: ${props => props.theme.background};
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 85%;
	transition: ${props => props.visible ? 'transform 0.15s' : 'transform 0.2s, z-index 0s 0.15s'};
	${props => !props.visible && 'transition: none;'}
	z-index: ${props => props.visible ? props.theme.zLayer7 : -1};

	display: none;

	${desktop`
		width: 340px;
	`}

	${mobile`
		transition: none;
	`}
`

const enhance = compose(
	connect(state => ({
		deck: state.deckbuilder.deck,
		menuCollapsed: state.persist.menuCollapsed,
		userId: state.persist.user.id
	})),
	withRouter
)

export default enhance(FixedCollapsible)
