import request from 'superagent'
import store from '../state/store'
import { setError } from '../state/actions/app'
import { domains, translationsMapping } from '../../config'
import { events, trackEvent } from './track'
import { isStandalone } from '../styles/media'

const endpointsShouldShowOfflineHint = [
	'/api/archetypes',
	'/api/cards',
	'/api/seasons',
	'/api/index',
	'/api/series',
	'/api/sets',

	'/api/cards/spoiler',
	'/api/decks',
	'/api/events',
	'/api/users'
]

/**
 * Sends an authenticated request.
 *
 * @param method   Request method
 * @param uri      Requested resource
 * @param data     Data
 * @param success  Success callback function
 * @param error    Error callback function
 */
export const send = (method, uri, data, success, error) => {
	const auth = store.getState().persist.authToken
	const language = translationsMapping[store.getState().persist.language]
	const cardLanguage = store.getState().persist.user.settings['app.cardNameLanguage']
	const game = store.getState().persist.user.settings['site.game'] || 'ygo'

	if (!uri) return

	const domain = process.env.NODE_ENV !== 'development' ? `https://${window.location.hostname || domains[language]}` : ''
	let http = request(method, `${domain}/api/${uri}`)

	// set auth token
	if (auth !== null) {
		http = http.auth(auth, { type: 'bearer' })
	}

	// set language
	http = http.set('accept-language', language)
	http = http.set('accept-card-language', cardLanguage)
	http = http.set('accept-game', game)
	http = http.set('standalone', isStandalone())

	// send data if any
	if (data !== null) {
		http.send(data)
	}

	http.then((response) => {
		// check if offline
		if (typeof response === 'undefined') {
			// show hint for real pages only
			for (const item of endpointsShouldShowOfflineHint) {
				if (`/api/${uri}`.startsWith(item)) {
					store.dispatch(setError('offline'))
					break
				}
			}

			if (error) {
				error()
			}

			return
		}

		if (response.status === 200 && response.headers.location) {
			send(method, response.headers.location.replace('/api/', ''), data, success, error)
			return
		}

		let data = null

		if (response.text) {
			try {
				data = JSON.parse(response.text)
			} catch (e) {
				data = response.text
			}
		}

		const responseURL = response.xhr.responseURL.replace(window.location.origin, '')

		success(data, response.req.url !== responseURL ? responseURL : null, response.status)
	}).catch((err) => {
		const status = Number(err.status)
		const { response } = err

		if (response) {
			const { statusText } = response

			if (typeof error !== 'undefined') {
				error(status, response.body)
			} else if (status === 401) {
				trackEvent(events.UNAUTHORIZED, `${status} ${statusText} (/api/${uri})`)
				store.dispatch(setError('unauthorized'))
			} else if (status === 403) {
				trackEvent(events.FORBIDDEN, `${status} ${statusText} (/api/${uri})`)
				store.dispatch(setError('forbidden'))
			} else if (status === 503) {
				store.dispatch(setError('maintenance'))
			} else if (status !== 404) {
				trackEvent(events.SERVER_ERROR, `${status} ${statusText} (/api/${uri})`)
				store.dispatch(setError('serverError'))
			}

			if (status === 500) {
				trackEvent(events.SERVER_ERROR, `${status} ${statusText} (/api/${uri})`)
			}
		}
		// maintenance mode, preflight fails due to CORS
		else if (err.toString().includes('Request has been terminated')) {
			store.dispatch(setError('maintenance'))
		}
	})

	return http
}

/**
 * Sends an authenticated GET request.
 *
 * @param uri     Requested resource
 * @param success Success callback function
 * @param error   Error callback function
 */
export const get = (uri, success, error) => send('GET', uri, null, success, error)

/**
 * Sends an authenticated POST request.
 *
 * @param uri     Requested resource
 * @param data    Data
 * @param success Success callback function
 * @param error   Error callback function
 */
export const post = (uri, data, success, error) => send('POST', uri, data, success, error)

/**
 * Sends an authenticated PUT request.
 *
 * @param uri     Requested resource
 *  * @param data    Data
 * @param success Success callback function
 * @param error   Error callback function
 */
export const put = (uri, data, success, error) => send('PUT', uri, data, success, error)

/**
 * Sends an authenticated PATCH request.
 *
 * @param uri     Requested resource
 * @param data    Data
 * @param success Success callback function
 * @param error   Error callback function
 */
export const patch = (uri, data, success, error) => send('PATCH', uri, data, success, error)

/**
 * Sends an authenticated DELETE request.
 *
 * @param uri      Requested resource
 * @param success Success callback function
 * @param error   Error callback function
 */
export const remove = (uri, success, error) => send('DELETE', uri, null, success, error)
